import React from "react";
import {useSelector} from 'react-redux';
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar } from "shards-react";

import {getUser} from '../../../reducers/rootReducer';

import NavbarSearch from "./NavbarSearch";
import NavbarPropertySearch from "./NavbarPropertySearch";
import NavbarContactSearch from "./NavbarContactSearch";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";

const MainNavbar = ({ layout, stickyTop }) => {
  const user = useSelector(state => getUser(state).user);

  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );

  const renderNavBar = () => {
    if (window.location.href.indexOf('properties') !== -1) {
      return (
        <NavbarPropertySearch />
      )
    }
    if (window.location.href.indexOf('contacts') !== -1) {
      return (
        <NavbarContactSearch />
      )
    }
    return <NavbarSearch />;
  }

  return (
    <div className={classes}>
      <Container className="p-0">
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0">
          {renderNavBar()}
          <NavbarNav user={user} />
          <NavbarToggle />
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default MainNavbar;
