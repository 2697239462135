import firebase from 'firebase';
import {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import { getDefaultFeedbackForm, getDefaultOpenhouseForm, generateDynamicLink, getE164 } from '../utils/tools';
import {WEB_APP_URL} from '../environmentVariables';
import * as actionConstants from '../actionConstants';

export const getViewings = () => async dispatch => {
  dispatch({ type: actionConstants.LOADING_VIEWINGS });
  let currentUser = await firebase.auth().currentUser;

  return firebase
    .firestore()
    .collection('users')
    .doc(currentUser.uid)
    .collection('viewings')
    .get()
    .then(querySnapshot => {
      let viewings = [];
      querySnapshot.forEach(doc => {
        viewings.push({id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_VIEWINGS, payload: viewings})
    })
    .catch(error => console.log(error))
};

export const getLiveViewings = (userId) => dispatch => {
  dispatch({ type: actionConstants.LOADING_VIEWINGS });

  return firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('viewings')
    .onSnapshot(querySnapshot => {
      let viewings = [];
      querySnapshot.forEach(doc => {
        viewings.push({id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_VIEWINGS, payload: viewings})
    });
};

export const getLiveRequestedViewings = (userId) => dispatch => {
  dispatch({ type: actionConstants.LOADING_REQUESTED_VIEWINGS });

  return firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('requestedViewings')
    .onSnapshot(querySnapshot => {
      let requestedViewings = [];
      querySnapshot.forEach(doc => {
        requestedViewings.push({id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_REQUESTED_VIEWINGS, payload: requestedViewings})
    });
};

export const getLiveTours = (userId) => dispatch => {
  dispatch({ type: actionConstants.LOADING_TOURS });

  return firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('tours')
    .onSnapshot(querySnapshot => {
      let tours = [];
      querySnapshot.forEach(doc => {
        tours.push({id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_TOURS, payload: tours})
    });
};
