import React, {useEffect, useState} from "react";
import {Container, Row, Col, Alert, Card, CardHeader, CardBody} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import UserDetails from "../../components/user-profile/UserDetails";
import UserAccountDetails from "../../components/user-profile/UserAccountDetails";

const moment = require('moment');

const User = (props) => {
  console.log(props)
  const getIdFromPathname = (pathname) => {
    return pathname.substr(pathname.indexOf('/user-support/') + 14);
  };

  const [selectedUserId] = useState(getIdFromPathname(props.location.pathname));
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedViewing, setSelectedViewing] = useState(null);

  useEffect(() => {
    props.getSelectedUser(selectedUserId)
  }, []);

  useEffect(() => {
    setSelectedUser(props.selectedUser)
  }, [props.selectedUser]);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="User detail" subtitle="Super user tools" md="12" className="ml-sm-auto mr-sm-auto" />
      </Row>
      {selectedUser && selectedUser.user && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">{selectedUser.user.name}</h6>
              </CardHeader>
              <CardBody className="p-4 pb-3">
                <p>
                  ID: {selectedUser.user.id}<br />
                  Name: {selectedUser.user.name}<br />
                  Email: {selectedUser.user.email}<br />
                  Phone Number: {selectedUser.user.phoneNumber}<br />
                  Properties Link: <a href={selectedUser.user.viewingLink} target={'_blank'}>{selectedUser.user.viewingLink}</a><br />
                </p>
                <h6>Extra user data</h6>
                <p>
                {Object.entries(selectedUser.user).map(entry => {
                  if (!(entry[1] instanceof Object)) {
                    return (<span><span style={{fontWeight: 'bold'}}>{entry[0]}:</span> {entry[1]}<br/></span>)
                  }
                })}
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {selectedUser && selectedUser.properties && selectedUser.properties.length > 0 && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">{selectedUser.user.name} properties</h6>
              </CardHeader>
              <CardBody className="p-4 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Address
                    </th>
                    <th scope="col" className="border-0">
                      Available
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {selectedUser.properties.sort((a, b) => a.address - b.address).map(property => (
                    <tr>
                      <td>
                        <a href={'javascript: void(0);'} onClick={() => setSelectedProperty(property)}>View</a>
                      </td>
                      <td>{property.address}</td>
                      <td>{property.available ? 'Yes' : 'No'}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
          {selectedProperty && (
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">{selectedProperty.address}</h6>
                </CardHeader>
                <CardBody className="p-4 pb-3">
                  <p>
                    ID: {selectedProperty.id}<br />
                    User: <a href={'/user-support/' + selectedProperty.user}>{selectedProperty.user}</a><br />
                    Address: {selectedProperty.address}<br />
                    Availability: {selectedProperty.available ? 'Yes': 'No'}<br />
                    Booking Link: <a href={selectedProperty.viewingLink} target={'_blank'}>{selectedProperty.viewingLink}</a><br />
                  </p>
                  <h6>Extra property data</h6>
                  <p>
                    {Object.entries(selectedProperty).map(entry => {
                      if (!(entry[1] instanceof Object)) {
                        return (<span><span style={{fontWeight: 'bold'}}>{entry[0]}:</span> {entry[1]}<br /></span>)
                      }
                    })}
                  </p>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      )}
      {selectedUser && selectedUser.viewings && selectedUser.viewings.length > 0 && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">{selectedUser.user.name} Showings</h6>
              </CardHeader>
              <CardBody className="p-4 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Address
                    </th>
                    <th scope="col" className="border-0">
                      Date/Time
                    </th>
                    <th scope="col" className="border-0">
                      Status
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {selectedUser.viewings.sort((a, b) => moment(b.viewingDateTime).unix() - moment(a.viewingDateTime).unix()).map(viewing => (
                    <tr>
                      <td>
                        <a href={'javascript: void(0)'} onClick={() => setSelectedViewing(viewing)}>View</a>
                      </td>
                      <td>{viewing.propertyAddress}</td>
                      <td>{moment(viewing.viewingDateTime).format('MM-DD-Y HH:mm')}</td>
                      <td>{viewing.cancelled ? 'Cancelled' : moment() > moment(viewing.viewingDateTime) ? 'Past' : ''}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
          {selectedViewing && (
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">{selectedViewing.propertyAddress}</h6>
                </CardHeader>
                <CardBody className="p-4 pb-3">
                  <p>
                    {Object.entries(selectedViewing).map(entry => {
                      if (!(entry[1] instanceof Object)) {
                        return (<span><span style={{fontWeight: 'bold'}}>{entry[0]}:</span> {entry[1]}<br /></span>)
                      }
                    })}
                  </p>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      )}
    </Container>
  )
};

export default User;
