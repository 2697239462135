/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Badge,
  FormSelect,
  FormCheckbox,
  Modal,
  ModalHeader,
  ModalBody,
} from "shards-react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import {getTextCodeFromProperty, getIncomingTextCode, formatPhoneNumber} from "../../utils/tools";
import 'react-big-calendar/lib/css/react-big-calendar.css';

import PageTitle from "../../components/common/PageTitle";
import { event } from "react-ga";

const localizer = momentLocalizer(moment)

const Showings = (props) => {

  const [user, setUser] = useState(props.user);
  const [displayUser, setDisplayUser] = useState(props.user);
  const [eventList, setEventList] = useState([]);
  const [event, setEvent] = useState(null);
  const [showConfirmed, setShowConfirmed] = useState(true);
  const [showPending, setShowPending] = useState(true);
  const [showTours, setShowTours] = useState(true);

  useEffect(() => {
    document.title = 'Showings: Real Happy Dashboard';
    props.getLiveViewings(displayUser.id)
    props.getLiveRequestedViewings(displayUser.id)
    props.getLiveTours(displayUser.id)
  }, [displayUser])

  useEffect(() => {
    if (props.displayUser && props.displayUser.id) {
      setDisplayUser(props.displayUser)
    }
  }, [props.displayUser])

  useEffect(() => {
    generateEventsList(props.viewings, props.requestedViewings, props.tours)
  }, [props.viewings, props.requestedViewings, props.tours, showConfirmed, showPending, showTours])

  const generateEventsList = (viewings, requestedViewings, tours) => {
    let events = [];
    if (viewings) {
      viewings.map(viewing => {
        let property = props.properties.find(property => property.id === viewing.property)
        let contact = props.contacts.find(contact => contact.id === viewing.contact)
        if (property) {
          events.push({
            title: viewing.propertyAddress,
            start: moment(viewing.viewingDateTime).toDate(),
            end: moment(viewing.viewingDateTime).add(property.defaultViewingLength || 25, 'minutes').toDate(),
            allDay: false,
            confirmed: true,
            contactName: contact && contact.name,
            contactPhoneNumber: contact && formatPhoneNumber(contact.phoneNumber, contact.iso2),
            type: 'viewing',
          })
        }
      })
    }
    
    if (requestedViewings) {
      requestedViewings.map(requestedViewing => {
        let property = props.properties.find(property => property.id === requestedViewing.property)
        let contact = props.contacts.find(contact => contact.id === requestedViewing.contact)
        events.push({
          title: requestedViewing.propertyAddress,
          start: moment(requestedViewing.viewingDateTime).toDate(),
          end: moment(requestedViewing.viewingDateTime).add(property.defaultViewingLength, 'minutes').toDate(),
          allDay: false,
          confirmed: false,
          contactName: contact && contact.name,
          contactPhoneNumber: contact && formatPhoneNumber(contact.phoneNumber, contact.iso2),
          type: 'viewing',
        })
      })
    }

    if (tours) {
      tours.map(tour => {
        let contact = props.contacts.find(contact => contact.id === tour.buyer)
        let viewings = tour.selectedPlaces;
        let firstViewing = viewings.sort((a, b) => moment(a.viewingDateTime) - moment(b.viewingDateTime))[0]
        let lastViewing = viewings.sort((a, b) => moment(b.viewingDateTime) - moment(a.viewingDateTime))[0]
        let startTime = moment(firstViewing.viewingDateTime).toDate()
        let endTime = moment(lastViewing.viewingDateTime).add(displayUser.defaultViewingLength, 'minutes').toDate()

        events.push({
          title: tour.name,
          start: startTime,
          end: endTime,
          allDay: false,
          confirmed: false,
          contactName: contact && contact.name,
          contactPhoneNumber: '',
          viewings,
          type: 'tour',
        })
      })
    }

    if (!showConfirmed) {
      events = events.filter(event => event.type === 'viewing' && !event.confirmed)
    }

    if (!showPending) {
      events = events.filter(event => event.type === 'viewing' && event.confirmed)
    }

    if (!showTours) {
      events = events.filter(event => event.type !== 'tour')
    }

    setEventList(events)
  }

  const generateEventStyle = event => {
    if (event.type === 'tour') {
      return {backgroundColor: '#E7EFFB', fontSize: 11, height: 40, color: '#2F66D8'}
    }
    if (event.confirmed) {
      return {backgroundColor: '#E6F8EF', fontSize: 11, height: 40, color: '#4CB45B'}
    } else {
      return {backgroundColor: '#FDF6E9', fontSize: 11, height: 40, color: '#F29738'}
    }
    return null;
  }

  const generateEventDetailStyle = event => {
    if (event.type === 'tour') {
      return {backgroundColor: '#E7EFFB', fontSize: 14, color: '#2F66D8'}
    }
    if (event.confirmed) {
      return {backgroundColor: '#E6F8EF', fontSize: 14, color: '#4CB45B'}
    } else {
      return {backgroundColor: '#FDF6E9', fontSize: 14, color: '#F29738'}
    }
    return null;
  }

  const customSlotPropGetter = date => {
    return {
      style: {
        height: 100
      },
    }
  }

  const renderTourModal = event => {
    if (event) {
      return (
        <Modal
          open
          toggle={() => setEvent(null)}
        >
          <ModalBody style={generateEventDetailStyle(event)}>
            <Row>
              <Col xs={'10'}>
                <h5>Tour</h5>
              </Col>
              <Col xs={'2'}>
                <button type="button" class="close" onClick={() => setEvent(null)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                {moment(event.start).format('M/D/YYYY h:mm a')} - {moment(event.end).format('M/D/YYYY h:mm a')}<br />
                {event.title}<br />
                {event.contactName ? 'Name: ' + event.contactName : ''}<br /><br />
                {event.viewings.sort((a, b) => moment(a.viewingDateTime) - moment(b.viewingDateTime)).map((viewing, index) => (
                  <span>
                      Stop No.{(index + 1)}<br />
                      Place: {viewing.name} <br />
                      Time: {moment(viewing.viewingDateTime).format('M/D/YYYY h:mm a')}<br /><br />
                  </span>
                ))}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )
    }
  }

  const renderViewingModal = event => {
    if (event) {
      return (
        <Modal
          open
          toggle={() => setEvent(null)}
        >
          <ModalBody style={generateEventDetailStyle(event)}>
            <Row>
              <Col xs={'10'}>
                <h5>{event.confirmed ? 'Confirmed' : 'Unconfirmed'} showing</h5>
              </Col>
              <Col xs={'2'}>
                <button type="button" class="close" onClick={() => setEvent(null)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                {moment(event.start).format('M/D/YYYY h:mm a')} - {moment(event.end).format('M/D/YYYY h:mm a')}<br />
                {event.title}<br />
                Name: {event.contactName}<br />
                Phone number: {event.contactPhoneNumber}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )
    }
    return null;
  }

  const renderModal = event => {
    if (event) {
      if (event.type === 'tour') {
        return renderTourModal(event)
      } else {
        return renderViewingModal(event)
      }
    }
  }

  const renderEvent = ({event}) => {
    return (
      <div>
        {moment(event.start).format('h:mm a')} - {moment(event.end).format('h:mm a')}<br />
        {event.title}
      </div>
    )
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <Col xs="12" sm="8">
          <PageTitle sm="12" title={displayUser && displayUser.id === user.id ? 'My Showings' : displayUser.name + "'s Showings"} subtitle="Showings" className="text-sm-left" />
        </Col>
        <Col xs="12" sm="4" className={"mt-4 mt-sm-0"}>
          {displayUser && ['GDdm4AUQfgcZrhFo7GzdgkPI79t1', 'oEg4RMaj5HQvujnLbpZlU15G64P2'].indexOf(displayUser.id) !== -1 && (
            <FormSelect id="feInputUser" value={displayUser.id} onChange={event => props.getDisplayUser(event.target.value)} >
              <option value={'GDdm4AUQfgcZrhFo7GzdgkPI79t1'}>My showings</option>
              <option value={'oEg4RMaj5HQvujnLbpZlU15G64P2'}>Corri Jones showings</option>
            </FormSelect>
          )}
        </Col>
      </Row>

      {/* First Row of Posts */}
      <Row>
        <Col md='8' className="mb-4">
          <Card className={'mb-0'}>
            <CardHeader className="border-bottom">
              <h6 className="m-0">{displayUser.name}'s Calendar</h6>
            </CardHeader>
            <CardBody>
              <Calendar
                popup
                localizer={localizer}
                events={eventList}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 600 }}
                eventPropGetter={(event, start, end, isSelected) => { return ({style: generateEventStyle(event)})}}
                onSelectEvent={event => setEvent(event)}
                slotPropGetter={customSlotPropGetter}
                components={{
                  event: renderEvent
                }}
                min={moment('08:00', 'HH:mm').toDate()}
                max={moment('20:00', 'HH:mm').toDate()}
              />
            </CardBody>
          </Card>
        </Col>
        <Col md='4'>
          <Card small className="card-post card-post--1">
            <CardHeader className="border-bottom">
              Display options
            </CardHeader>
            <CardBody className='p-0'>
              <Container>
              <Row className='p-2 border-bottom'>
                <Col xs='2'>
                  <div style={{width: 30, height: 30, backgroundColor: '#E6F8EF', color: '#4CB45B'}}>
                    <i className="material-icons" style={{paddingLeft: 5, fontSize: 20, paddingTop: 3}}>check_box</i>
                  </div>
                </Col>
                <Col xs='6'>
                  Confirmed
                </Col>
                <Col xs='4'>
                  <FormCheckbox
                    checked={showConfirmed}
                    onChange={() => setShowConfirmed(!showConfirmed)}
                  />
                </Col>
              </Row>
              <Row className='p-2 border-bottom'>
                <Col xs='2'>
                  <div style={{width: 30, height: 30, backgroundColor: '#FDF6E9', color: '#F29738'}}>
                    <i className="material-icons" style={{paddingLeft: 5, fontSize: 20, paddingTop: 3}}>watch_later</i>
                  </div>
                </Col>
                <Col xs='6'>
                  Pending
                </Col>
                <Col xs='4'>
                  <FormCheckbox
                    checked={showPending}
                    onChange={() => setShowPending(!showPending)}
                  />
                </Col>
              </Row>
              <Row className='p-2'>
                <Col xs='2'>
                  <div style={{width: 30, height: 30, backgroundColor: '#E7EFFB', color: '#2F66D8'}}>
                    <i className="material-icons" style={{paddingLeft: 5, fontSize: 20, paddingTop: 3}}>timeline</i>
                  </div>
                </Col>
                <Col xs='6'>
                  Tours
                </Col>
                <Col xs='4'>
                  <FormCheckbox
                    checked={showTours}
                    onChange={() => setShowTours(!showTours)}
                  />
                </Col>
              </Row>
              </Container>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {renderModal(event)}
    </Container>
  );
}

export default Showings;
