import * as userActions from './userActions';
import * as loginActions from './loginActions';
import * as propertyActions from './propertyActions';
import * as supportActions from './supportActions';
import * as formActions from './formActions';
import * as contactActions from './contactActions';
import * as viewingActions from './viewingActions';

export const ActionCreators = {
  ...userActions,
  ...loginActions,
  ...propertyActions,
  ...supportActions,
  ...formActions,
  ...contactActions,
  ...viewingActions,
};
