import firebase from 'firebase';
import {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import { getDefaultFeedbackForm, getDefaultOpenhouseForm, generateDynamicLink, getE164 } from '../utils/tools';
import {WEB_APP_URL} from '../environmentVariables';
import * as actionConstants from '../actionConstants';

export const setContactsSearchTerm = term => dispatch => {
  dispatch({type: actionConstants.SET_CONTACTS_SEARCH_TERM, payload: term})
}

export const clearAddedContact = () => dispatch => {
  dispatch({type: actionConstants.CLEAR_ADDED_CONTACT})
}

export const getContacts = () => async dispatch => {
  dispatch({ type: actionConstants.LOADING_CONTACTS });
  let currentUser = await firebase.auth().currentUser;

  return firebase
    .firestore()
    .collection('users')
    .doc(currentUser.uid)
    .collection('contacts')
    .get()
    .then(querySnapshot => {
      let contacts = [];
      querySnapshot.forEach(doc => {
        contacts.push({id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_CONTACTS, payload: contacts})
    })
    .catch(error => console.log(error))
};

export const getLiveContacts = (userId) => dispatch => {
  dispatch({ type: actionConstants.LOADING_CONTACTS });

  return firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('contacts')
    .onSnapshot(querySnapshot => {
      let contacts = [];
      querySnapshot.forEach(doc => {
        contacts.push({id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_CONTACTS, payload: contacts})
    });
};

/**
 * Add a contact
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
 export const addContact = (data, callback) => {
  return (dispatch, getState) => {
    dispatch({type: actionConstants.ADDING_CONTACT});
    let phoneNumber = getE164(data.phoneNumber.replace(/[^0-9]+/g, ''), data.iso2);
    let email = data.email === '' ? null : data.email;
    const contactData = {
      name: data.name,
      phoneNumber: phoneNumber,
      email: email,
      contactType: data.contactType,
      origin: data.origin || null,
      feedbackSent: 0,
      iso2: data.iso2,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      notes: data.notes || null,
    };
    return firebase
      .firestore()
      .collection('users')
      .doc(getState().user.user.id)
      .collection('contacts')
      .add(contactData)
      .then(doc => {
        dispatch({
          type: actionConstants.ADDED_CONTACT,
          payload: {id: doc.id, user: getState().user.user.id, ...contactData},
        });
        callback && callback({id: doc.id, user: getState().user.user.id, ...contactData})
      });
  };
};

/**
 * Edit a contact
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
export const editContact = (contact, data, callback) => {
  if (data.phoneNumber) {
    return (dispatch, getState) => {
      dispatch({type: actionConstants.UPDATING_CONTACT});
      let phoneNumber = getE164(data.phoneNumber.replace(/[^0-9]+/g, ''), data.iso2);
      let email = data.email === '' ? null : data.email;
      let contactData = {
        name: data.name,
        phoneNumber: phoneNumber,
        email: email,
        contactType: data.contactType.toLowerCase(),
        iso2: data.iso2,
        updatedAt: Date.now(),
      }
      return firebase
        .firestore()
        .collection('users')
        .doc(getState().user.user.id)
        .collection('contacts')
        .doc(contact.id)
        .update(contactData)
        .then(() => {
          dispatch({type: actionConstants.UPDATED_CONTACT, payload: {id: contact.id, user: getState().user.user.id, ...contactData}});
          callback && callback({id: contact.id, user: getState().user.user.id, ...contactData})
        });
    };
  }
};

/**
 * Create CSV export
 *
 * @returns {function(*, *): Promise<void>}
 */
 export const exportContactsCSV = () => {
  return (dispatch, getState) => {
    dispatch({type: actionConstants.SENDING_CONTACTS_CSV});
    return firebase
      .firestore()
      .collection('contactsCSVRequest')
      .add({
        user: getState().user.user.id,
        userEmail: getState().user.user.email,
        contacts: getState().contact.contacts,
      })
      .then(() => {
        dispatch({type: actionConstants.SENT_CONTACTS_CSV});
      });
  };
};
