import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import SidebarNavItemOpener from "./SidebarNavItemOpener";
import SidebarNavSubItem from "./SidebarNavSubItem";

const sidebarNavItems = [
  // {
  //   title: "Add New Post",
  //   htmlBefore: '<i class="material-icons">note_add</i>',
  //   to: "/add-new-post",
  // },
  {
    title: "Forms & Components",
    htmlBefore: '<i class="material-icons">view_module</i>',
    to: "/components-overview",
  },
  {
    title: "Tables",
    htmlBefore: '<i class="material-icons">table_chart</i>',
    to: "/tables",
  },
  // {
  //   title: "Errors",
  //   htmlBefore: '<i class="material-icons">error</i>',
  //   to: "/errors",
  // },
];

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)
    const isSettingsSubPage = window.location.href.indexOf('showing-defaults') !== -1 || window.location.href.indexOf('calendar-link-settings') !== -1
    this.state = {
      navItems: sidebarNavItems,
      showSettingsSubItems: isSettingsSubPage,
    };
  }

  render() {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {/*<SidebarNavItem
            item={{
              title: "Dashboard",
              to: "/dashboard",
              htmlBefore: '<i class="material-icons">edit</i>',
              htmlAfter: ""
            }}
          />*/}
          <SidebarNavItem
            item={{
              title: "My Properties",
              htmlBefore: '<i class="material-icons">home</i>',
              to: "/properties",
            }}
          />
          <SidebarNavItem
            item={{
              title: "Contacts",
              htmlBefore: '<i class="material-icons">people</i>',
              to: "/contacts",
            }}
          />
          <SidebarNavItem
            item={{
              title: "Showings",
              htmlBefore: '<i class="material-icons">calendar_month</i>',
              to: "/showings",
            }}
          />
          <SidebarNavItemOpener
            item={{
              title: "Settings",
              htmlBefore: '<i class="material-icons">settings</i>',
            }}
            //onClick={() => this.setState({showSettingsSubItems: !this.state.showSettingsSubItems})}
            onMouseOver={() => this.setState({showSettingsSubItems: true})}
          />
          {this.state.showSettingsSubItems && (
            <div onMouseLeave={() => this.setState({showSettingsSubItems: false})}>
              <SidebarNavSubItem
                item={{
                  title: "Showing defaults",
                  to: "/showing-defaults",
                  htmlBefore: '<i class="material-icons">settings</i>',
                  htmlAfter: ""
                }}
              />
              <SidebarNavSubItem
                item={{
                  title: "Calendar link settings",
                  htmlBefore: '<i class="material-icons">settings</i>',
                  to: "/calendar-link-settings",
                }}
              />
            </div>
          )}
          {this.props.user.superAdmin && !sidebarNavItems.find(item => item.title === 'Support') && (
            <SidebarNavItem
            item={{
              title: "Support",
              htmlBefore: '<i class="material-icons">error</i>',
              to: "/support",
            }}
          />
          )}
          {/* {items.map((item, idx) => (
              <SidebarNavItem key={idx} item={item} />
            ))} */}
            
          </Nav>
      </div>
    )
  }
}

export default SidebarNavItems;
