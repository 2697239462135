import React, {useState, useEffect} from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroupItem,
  Form,
  ListGroup,
  FormInput,
  FormGroup, Button
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";

const Support = (props) => {

  const [userSearchTerm, setUserSearchTerm] = useState(null);
  const [propertySearchTerm, setPropertySearchTerm] = useState(null);
  const [searchUserResults, setSearchUserResults] = useState(null);
  const [searchPropertyResults, setSearchPropertyResults] = useState(null);

  useEffect(() => {
    setSearchUserResults(props.searchUserResults);
    setSearchPropertyResults(null);
  }, [props.searchUserResults]);

  useEffect(() => {
    setSearchPropertyResults(props.searchPropertyResults);
    setSearchUserResults(null);
  }, [props.searchPropertyResults]);

  const processSearch = () => {
    if (userSearchTerm) {
      props.searchUsers(userSearchTerm)
    }
    if (propertySearchTerm) {
      props.searchProperties(propertySearchTerm)
    }
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Support" subtitle="Super User Tools" className="text-sm-left" />
      </Row>

      {/* Default Light Table */}
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Search</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        <FormGroup>
                          <label htmlFor="feInputAddress">Search users</label>
                          <FormInput id="searchUser" placeholder="Search users" onChange={event => {
                            setPropertySearchTerm(null);
                            setUserSearchTerm(event.target.value);
                          }}/>
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="feInputAddress">Search properties</label>
                          <FormInput id="searchProperty" placeholder="Search properties" onChange={event => {
                            setUserSearchTerm(null);
                            setPropertySearchTerm(event.target.value)
                          }}/>
                        </FormGroup>
                        <Button type="button" onClick={() => processSearch()}>Search</Button>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {searchUserResults && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Results</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                {searchUserResults && (
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          Name
                        </th>
                        <th scope="col" className="border-0">
                          Email
                        </th>
                        <th scope="col" className="border-0">
                          Phone Number
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {searchUserResults.map(user => (
                      <tr>
                        <td><a href={'/user-support/' + user.id}>View</a></td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.phoneNumber}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {searchPropertyResults && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Results</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                {searchPropertyResults && (
                  <table className="table mb-0">
                    <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Address
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {searchPropertyResults.map(property => (
                      <tr>
                        <td><a href={'/property-support/' + property.id}>View</a></td>
                        <td>{property.address}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  )
};

export default Support;
