// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  searchingUsers: false,
  searchedUsers: false,
  searchUserResults: [],
  searchingProperties: false,
  searchedProperties: false,
  searchPropertyResults: [],
  selectedUser: {},
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.SEARCHING_USERS:
      return {
        ...state, searchingUsers: true, searchedUsers: false
      };
    case actionConstants.SEARCH_USER_RESULTS:
      return {
        ...state, searchingUsers: false, searchedUsers: true, searchUserResults: action.payload
      };
    case actionConstants.LOADED_SELECTED_USER:
      return {
        ...state, selectedUser: action.payload
      };
    case actionConstants.SEARCHING_PROPERTIES:
      return {
        ...state, searchingProperties: true, searchedProperties: false
      };
    case actionConstants.SEARCH_PROPERTY_RESULTS:
      return {
        ...state, searchingProperties: false, searchedProperties: true, searchPropertyResults: action.payload
      };
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getSupport = ({
searchingUsers, searchedUsers, searchUserResults, selectedUser, searchingProperties, searchedProperties, searchPropertyResults
}) => ({
  searchingUsers,
  searchedUsers,
  searchUserResults,
  selectedUser,
  searchingProperties,
  searchedProperties,
  searchPropertyResults
});
