/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
} from "shards-react";
import {formatPhoneNumber} from "../../utils/tools";

import PageTitle from "../../components/common/PageTitle";
import { clearAddedContact } from "../../actions/contactActions";

const Contacts = (props) => {

  const [contacts, setContacts] = useState(props.contacts || []);
  const [contactsSearchTerm, setContactsSearchTerm] = useState(props.contactsSearchTerm || '')
  const [sentContactsCsv, setSentContactsCsv] = useState(false)
  const [addedContact, setAddedContact] = useState(props.addedContact)
  const [updatedContact, setUpdatedContact] = useState(props.updatedContact)
  

  useEffect(() => {
    props.getLiveContacts(props.user.id)
    document.title = 'Contacts: Real Happy Dashboard';
  }, [])

  useEffect(() => {
    if (props.contacts !== contacts) {
      setContacts(props.contacts)
    }
  }, [props.contacts])

  useEffect(() => {
    if (props.sentContactsCsv) {
      setSentContactsCsv(true);
      setTimeout(() => setSentContactsCsv(false), 10000);
    }
  }, [props.sentContactsCsv])

  useEffect(() => {
    if (props.addedContact) {
      setAddedContact(true);
      setTimeout(() => {
        setAddedContact(false)
        props.clearAddedContact()
      }, 10000);
    }
  }, [props.addedContact])

  useEffect(() => {
    if (props.updatedContact) {
      setUpdatedContact(true);
      setTimeout(() => {
        setUpdatedContact(false)
        props.clearAddedContact()
      }, 10000);
    }
  }, [props.updatedContact])

  useEffect(() => {
    if (props.contactsSearchTerm !== contactsSearchTerm) {
      setContactsSearchTerm(props.contactsSearchTerm)
    }
  }, [props.contactsSearchTerm])

  const filterContacts = () => {
    let sortedContacts = contacts;
    if (contactsSearchTerm && contactsSearchTerm.length > 0) {
      sortedContacts = contacts.filter(contact => {
        return (contact.name && contact.name.toLowerCase().indexOf(contactsSearchTerm.toLowerCase()) !== -1) || (contact.phoneNumber && contact.phoneNumber.indexOf(contactsSearchTerm.toLowerCase()) !== -1) || (contact.email && contact.email.toLowerCase().indexOf(contactsSearchTerm.toLowerCase()) !== -1)
      })
    }
    sortedContacts = sortedContacts.sort((a, b) => a.name && b.name && a.name.localeCompare(b.name));
    return sortedContacts
  }

  // filterProperties() {
  //   let properties = this.state.properties;
  //   properties = properties.sort((a, b) => b.createdAt - a.createdAt);
  //   if (this.state.propertySearchTerm && this.state.propertySearchTerm !== 'undefined' && this.state.propertySearchTerm.length > 0) {
  //     properties = properties.filter(property => (property.name && property.name.toLowerCase().indexOf(this.state.propertySearchTerm.toLowerCase()) !== -1) || (property.address && property.address.toLowerCase().indexOf(this.state.propertySearchTerm.toLowerCase()) !== -1))
  //   }
  //   if (this.state.availability === 'all') {
  //     return properties;
  //   }
  //   let availability = this.state.availability === 'available';
  //   return properties.filter(property => property.available === availability);
  // }

  const renderContactType = (contactType) => {
    let backgroundColor;
    switch (contactType) {
      case 'lead':
        backgroundColor = '#24AFF5';
        break;
      case 'agent':
        backgroundColor = '#F5A622';
        break;
      case 'buyer':
      case 'other':  
        backgroundColor = '#9B9B9B';
        break;
      case 'client':
        backgroundColor = '#F52395';
        break;  
    }
    return (
      <div style={{backgroundColor, color: '#FFFFFF', width: 65, paddingTop: 6, paddingBottom: 4, textAlign: 'center', borderRadius: 5, fontSize: 11}}>
        {contactType?.toUpperCase()}
      </div>
    )
  }

  return (
    <Container fluid className="main-content-container px-0">
      {sentContactsCsv && (
        <Alert className="mb-0" theme={'success'}>
          <i className="fa fa-info mx-2"></i> Your contacts are being generated and sent to you by email
        </Alert>
      )}
      {addedContact && (
        <Alert className="mb-0" theme={'success'}>
          <i className="fa fa-info mx-2"></i> {props.contact.name} has been succesfully added!
        </Alert>
      )}
      {updatedContact && (
        <Alert className="mb-0" theme={'success'}>
          <i className="fa fa-info mx-2"></i> {props.contact.name} has been succesfully updated!
        </Alert>
      )}
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col xs="12" sm="8">
            <PageTitle sm="12" title="Contacts" subtitle="Contacts" className="text-sm-left" />
          </Col>
          {/* <Col xs="12" sm="4" className={"mt-4 mt-sm-0"}>
            <FormSelect id="feInputState" onChange={event => this.setState({availability: event.target.value})}>
              <option value={'all'}>All properties</option>
              <option value={'available'}>Available properties</option>
              <option value={'unavailable'}>Unavailable properties</option>
            </FormSelect>
          </Col> */}
        </Row>

        {/* First Row of Posts */}
        <Row>
          <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <Row>
                <Col xs="12" sm="4">
                  <h6 className="m-0"></h6>
                </Col>
                <Col xs="12" sm="8" className='text-right'>
                <Button
                    className="mr-1"
                    theme="secondary"
                    onClick={() => props.exportContactsCSV()}
                  >Export Contacts</Button>
                  <Button
                    theme="accent"
                    onClick={() => window.location.href='/add-contact'}
                  >Add Contact</Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-0">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Name
                    </th>
                    <th scope="col" className="border-0">
                      Type
                    </th>
                    <th scope="col" className="border-0 d-none d-md-table-cell">
                      Mobile number
                    </th>
                    <th scope="col" className="border-0 d-none d-md-table-cell">
                      Email address
                    </th>
                    <th scope="col" className="border-0">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filterContacts().length > 0 && filterContacts().map(contact => {
                    return (
                      <tr>
                        <td>
                          {contact.name}<br />
                          <span className="d-md-none"><a href={'mailto:' + contact.email}>{contact.email}</a></span><br />
                          <span className="d-md-none">{contact.phoneNumber && formatPhoneNumber(contact.phoneNumber, contact.iso2)}</span>
                        </td>
                        <td>
                          {renderContactType(contact.contactType)}
                        </td>
                        <td className='d-none d-md-table-cell'>
                          {contact.phoneNumber && formatPhoneNumber(contact.phoneNumber, contact.iso2)}
                        </td>
                        <td className='d-none d-md-table-cell'>
                          <a href={'mailto:' + contact.email}>{contact.email}</a>
                        </td>
                        <td className='text-right'>
                          <a href={'/edit-contact/' + contact.id}>Edit</a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CardBody>
          </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Contacts;
