// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  loadingContacts: false,
  loadedContacts: false,
  contacts: [],
  contactsSearchTerm: '',
  addingContact: false,
  addedContact: false,
  updatingContact: false,
  updatedContact: false,
  contact: null,
  sendingContactsCsv: false,
  sentContactsCsv: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_CONTACTS:
      return {
        ...state, loadingContacts: true, loadedContacts: false, sentContactsCsv: false
      };
    case actionConstants.LOADED_CONTACTS:
      return {
        ...state, loadingContacts: false, loadedContacts: true, contacts: action.payload
      };
    case actionConstants.SET_CONTACTS_SEARCH_TERM:
      return {
        ...state, contactsSearchTerm: action.payload
      };
    case actionConstants.ADDING_CONTACT:
      return {
        ...state, addingContact: true, addedContact: false
      };
    case actionConstants.ADDED_CONTACT:
      return {
        ...state, addingContact: false, addedContact: true, contact: action.payload
      };
    case actionConstants.UPDATING_CONTACT:
      return {
        ...state, updatingContact: true, updatedContact: false
      };
    case actionConstants.UPDATED_CONTACT:
      return {
        ...state, updatingContact: false, updatedContact: true, contact: action.payload
      };  
    case actionConstants.SENDING_CONTACTS_CSV:
      return {
        ...state, sendingContactsCsv: true, sentContactsCsv: false
      };
    case actionConstants.SENT_CONTACTS_CSV:
      return {
        ...state, sendingContactsCsv: false, sentContactsCsv: true
      };
    case actionConstants.CLEAR_ADDED_CONTACT:
      return {
        ...state, addingContact: false, addedContact: false, updatingContact: false, updatedContact: false, contact: action.payload
      };     
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getContact = ({
  loadingContacts, loadedContacts, contacts, contactsSearchTerm, addingContact, addedContact, contact, sendingContactsCsv, sentContactsCsv, updatingContact, updatedContact
}) => ({
  loadingContacts,
  loadedContacts,
  contacts,
  contactsSearchTerm,
  addingContact,
  addedContact,
  contact,
  sendingContactsCsv,
  sentContactsCsv,
  updatingContact,
  updatedContact
});
