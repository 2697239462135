export const LOGGING_IN = 'LOGGING_IN';
export const VERIFICATION_CODE_SENT = 'VERIFICATION_CODE_SENT';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const VERIFICATION_CHECKING = 'VERIFICATION_CHECKING';
export const VERIFICATION_FAILED = 'VERIFICATION_FAILED';
export const VERIFICATION_PASSED = 'VERIFICATION_PASSED';

export const LOADING_USER = 'LOADING_USER';
export const LOADED_USER = 'LOADED_USER';
export const LOADING_USER_DATA = 'LOADING_USER_DATA';
export const SAVED_USER_AVATAR = 'SAVED_USER_AVATAR';
export const SAVED_COMPANY_LOGO = 'SAVED_COMPANY_LOGO';
export const UPDATED_USER = 'UPDATED_USER';
export const SAVED_USER_PRIMARY_COLOR = 'SAVED_USER_PRIMARY_COLOR';
export const LOADING_DISPLAY_USER = 'LOADING_DISPLAY_USER';
export const LOADED_DISPLAY_USER = 'LOADED_DISPLAY_USER';

export const LOADING_PROPERTIES = 'LOADING_PROPERTIES';
export const LOADED_PROPERTIES = 'LOADED_PROPERTIES';
export const SET_PROPERTY_SEARCH_TERM = 'SET_PROPERTY_SEARCH_TERM';
export const ADDING_PROPERTY = 'ADDING_PROPERTY';
export const ADDED_PROPERTY = 'ADDED_PROPERTY';
export const UPDATING_PROPERTY = 'UPDATING_PROPERTY';
export const UPDATED_PROPERTY = 'UPDATED_PROPERTY';

export const SEARCHING_USERS = 'SEARCHING_USERS';
export const SEARCH_USER_RESULTS = 'SEARCH_USER_RESULTS';
export const SEARCHING_PROPERTIES = 'SEARCHING_PROPERTIES';
export const SEARCH_PROPERTY_RESULTS = 'SEARCH_PROPERTY_RESULTS';
export const LOADED_SELECTED_USER = 'LOADED_SELECTED_USER';

export const LOADING_FORMS = 'LOADING_FORMS';
export const LOADED_FORMS = 'LOADED_FORMS';

export const LOADING_CONTACTS = 'LOADING_CONTACTS';
export const LOADED_CONTACTS = 'LOADED_CONTACTS';
export const SET_CONTACTS_SEARCH_TERM = 'SET_CONTACTS_SEARCH_TERM';
export const ADDING_CONTACT = 'ADDING_CONTACT';
export const ADDED_CONTACT = 'ADDED_CONTACT';
export const UPDATING_CONTACT = 'UPDATING_CONTACT';
export const UPDATED_CONTACT = 'UPDATED_CONTACT';
export const CLEAR_ADDED_CONTACT = 'CLEAR_ADDED_CONTACT';
export const SENDING_CONTACTS_CSV = 'SENDING_CONTACTS_CSV';
export const SENT_CONTACTS_CSV = 'SENT_CONTACTS_CSV';

export const LOADING_VIEWINGS = 'LOADING_VIEWINGS';
export const LOADED_VIEWINGS = 'LOADED_VIEWINGS';
export const LOADING_REQUESTED_VIEWINGS = 'LOADING_REQUESTED_VIEWINGS';
export const LOADED_REQUESTED_VIEWINGS = 'LOADED_REQUESTED_VIEWINGS';
export const LOADING_TOURS = 'LOADING_TOURS';
export const LOADED_TOURS = 'LOADED_TOURS';
