// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  loadingViewings: false,
  loadedViewings: false,
  viewings: [],
  loadingRequestedViewings: false,
  loadedRequestedViewings: false,
  requestedViewings: [],
  loadingTours: false,
  loadedTours: false,
  tours: [],
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_VIEWINGS:
      return {
        ...state, loadingViewings: true, loadedViewings: false
      };
    case actionConstants.LOADED_VIEWINGS:
      return {
        ...state, loadingViewings: false, loadedViewings: true, viewings: action.payload
      };
    case actionConstants.LOADING_REQUESTED_VIEWINGS:
      return {
        ...state, loadingViewings: true, loadedViewings: false
      };
    case actionConstants.LOADED_REQUESTED_VIEWINGS:
      return {
        ...state, loadingViewings: false, loadedViewings: true, requestedViewings: action.payload
      };
    case actionConstants.LOADING_TOURS:
      return {
        ...state, loadingTours: true, loadedTours: false
      };
    case actionConstants.LOADED_TOURS:
      return {
        ...state, loadingTours: false, loadedTours: true, tours: action.payload
      };    
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getViewing = ({
  loadingViewings, loadedViewings, viewings, loadingRequestedViewings, loadedRequestedViewings, requestedViewings, loadingTours, loadedTours, tours
}) => ({
  loadingViewings,
  loadedViewings,
  viewings,
  loadingRequestedViewings,
  loadedRequestedViewings,
  requestedViewings,
  loadingTours,
  loadedTours,
  tours,
});
