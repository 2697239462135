import Login from './Login';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getLogin, getUser} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {codeSent, checkingCode, codeFailed, loggingIn, loginFail, codePassed} = getLogin(
    state,
  );
  const {loadingUser, loadedUser, user} = getUser(state);

  return {
    codeSent,
    checkingCode,
    codeFailed,
    codePassed,
    loggingIn,
    loginFail,
    loadingUser,
    loadedUser,
    user,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
