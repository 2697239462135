/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  FormSelect,
} from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faMeh, faFrown } from '@fortawesome/free-solid-svg-icons';
import {getTextCodeFromProperty, getIncomingTextCode} from "../../utils/tools";

import PageTitle from "../../components/common/PageTitle";

const Properties = (props) => {

  const [properties, setProperties] = useState(props.properties)
  const [user, setUser] = useState(props.user)
  const [displayUser, setDisplayUser] = useState(props.user)
  const [availability, setAvailability] = useState('available')
  const [propertySearchTerm, setPropertySearchTerm] = useState(props.propertySearchTerm)

  useEffect(() => {
    props.getLiveProperties(displayUser.id);
    props.getLiveContacts(displayUser.id);
    props.getLiveForms(displayUser.id);
    document.title = 'My Properties: Real Happy Dashboard';
  }, [displayUser])

  useEffect(() => {
    if (props.displayUser && props.displayUser.id) {
      setDisplayUser(props.displayUser)
    }
  }, [props.displayUser])

  useEffect(() => {
    setProperties(props.properties)
  }, [props.properties])

  useEffect(() => {
    setPropertySearchTerm(props.propertySearchTerm)
  }, [props.propertySearchTerm])

  const filterProperties = () => {
    let sortedProperties = properties.sort((a, b) => b.createdAt - a.createdAt);
    if (propertySearchTerm && propertySearchTerm !== 'undefined' && propertySearchTerm.length > 0) {
      sortedProperties = sortedProperties.filter(property => (property.name && property.name.toLowerCase().indexOf(propertySearchTerm.toLowerCase()) !== -1) || (property.address && property.address.toLowerCase().indexOf(propertySearchTerm.toLowerCase()) !== -1))
    }
    if (availability === 'all') {
      return sortedProperties;
    }
    let isAvailable = availability === 'available';
    if (isAvailable) {
      return sortedProperties.filter(property => property.available === true);
    } else {
      return sortedProperties.filter(property => property.available !== true);
    }
    
  }

  const renderFeedbackRating = (rating) => {
    switch (parseInt(rating.toFixed(0))) {
      case 5:
        return (
          <FontAwesomeIcon icon={faSmile} style={{fontSize: 20}}  color={'#68D266'} />
        )
      case 4:
        return (
          <FontAwesomeIcon icon={faSmile} style={{fontSize: 20}}  color={'#B2D267'} />
        )  
      case 3:
        return (
          <FontAwesomeIcon icon={faMeh} style={{fontSize: 20}} color={'#FAC702'} />
        )  
      case 2:
        return (
          <FontAwesomeIcon icon={faFrown} style={{fontSize: 20}}  color={'#DF8A52'} />
        ) 
      case 1:
        return (
          <FontAwesomeIcon icon={faFrown} style={{fontSize: 20}}  color={'#DF5364'} />
        )  
      case 0:
        return (
          <FontAwesomeIcon icon={faMeh} style={{fontSize: 20}}  color={'#EEEEEE'} />
        )      
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <Col xs="12" sm="4">
          <PageTitle sm="12" title="My Properties" subtitle="Properties" className="text-sm-left" />
        </Col>
        <Col xs="12" sm="4" className={"mt-4 mt-sm-0"} style={{paddingRight: 4}}>
          {displayUser && ['GDdm4AUQfgcZrhFo7GzdgkPI79t1', 'oEg4RMaj5HQvujnLbpZlU15G64P2'].indexOf(displayUser.id) !== -1 && (
            <FormSelect id="feInputUser" value={displayUser.id} onChange={event => props.getDisplayUser(event.target.value)} >
              <option value={'GDdm4AUQfgcZrhFo7GzdgkPI79t1'}>My properties</option>
              <option value={'oEg4RMaj5HQvujnLbpZlU15G64P2'}>Corri Jones properties</option>
            </FormSelect>
          )}
        </Col>
        <Col xs="12" sm="4" className={"mt-4 mt-sm-0"}>
          <FormSelect id="feInputState" value={availability} onChange={event => setAvailability(event.target.value)}>
            <option value={'all'}>All properties</option>
            <option value={'available'}>Available properties</option>
            <option value={'unavailable'}>Unavailable properties</option>
          </FormSelect>
        </Col>
      </Row>

      {/* First Row of Posts */}
      <Row>
        {displayUser && displayUser.id === user.id && (
          <Col lg="3" md="6" sm="12" className="mb-4">
            <Link to={'/add-property'} style={{textDecoration: 'none'}}>
              <Card small className="card-post card-post--1" style={{minHeight: '356px'}}>
                <div
                  className="card-post__image"
                  style={{ backgroundImage: `url(${require('../../images/large_plus.png')})`, backgroundSize: 'auto'}}>
                  </div>
                <CardBody className="text-center" style={{paddingTop: '1rem', color: '#3D3C3E'}}>
                  <h7 className="card-title mb-3">
                    Add Property
                  </h7>
                  <p className="card-text mt-3" style={{ fontSize: '12px', color: '#BCBDC0'}}>
                    Add a new property to start scheduling showings, collecting leads and getting feedback
                  </p>
                </CardBody>
              </Card>
            </Link>
          </Col>
        )}
        {props.loadingProperties && props.properties.length === 0 && (
          <Col className="mb-4 pl-4">
            <Row>
              <Col className="mb-4 pl-3">
                <p>&nbsp;Loading properties...</p>
              </Col>
            </Row>
          </Col>
        )}
        {filterProperties().length === 0 && props.loadedProperties && (
          <Col className="mb-4 pl-4">
            <Row>
              <Col className="mb-4 pl-3">
                <p>&nbsp;No properties found</p>
              </Col>
            </Row>
          </Col>
        )}
        {filterProperties().map((property, idx) => {
          let image = require("../../images/no_image.png");
          if (property.cover) {
            image = property.cover
          } else if (property.media && property.media.length > 0) {
            image = property.media[0].url
          }
          let linkTo = displayUser && displayUser.id === user.id ? '/edit-property/' + property.id : '#'
          let ratingTotal = 0;
          let ratingCount = 0;
          if (property?.feedback?.length > 0) {
            property.feedback.map(feedback => {
              ratingCount = ratingCount + 1;
              ratingTotal = ratingTotal + feedback.rating;
            })
          }
          let rating = isNaN(ratingTotal/ratingCount) ? 0 : ratingTotal/ratingCount;
          return (
            <Col lg="3" md="6" sm="12" className="mb-4" key={idx}>
              <Card small className="card-post card-post--1" style={{minHeight: '331px'}}>
                <Link to={linkTo} style={{textDecoration: 'none'}}>
                  <div
                    className="card-post__image"
                    style={{ backgroundImage: `url(${image})` }}
                  >
                    <Badge
                      pill
                      className={`card-post__category`}
                      style={{backgroundColor: property.available ? '#67D266' : '#DF5264'}}
                    >
                      {property.available ? 'Available' : 'Unavailable'}
                    </Badge>
                    <div className="card-post__author d-flex">
                      <div
                        href="#"
                        className="card-post__author-avatar"
                        style={{ backgroundImage: `url('${displayUser.avatar}')` }}
                      >
                        {displayUser.name}
                      </div>
                    </div>
                  </div>
                </Link>
                <CardBody>
                  <Link to={'/edit-property/' + property.id} style={{textDecoration: 'none'}}>
                    <h7 className="card-title mb-3">
                      <div href="#" className="text-fiord-blue">
                        {property.address}
                      </div>
                    </h7>
                  </Link>
                  <p className="card-text mt-3">
                    <a href={property.viewingLink} target={'_blank'} rel="noopener noreferrer">{'View Property link'}</a>
                  </p>
                  <div style={{flexDirection: 'row', display: 'flex'}}>
                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', fontSize: 10, color: '#B6B7BB'}}>
                      <div style={{flex: 4, display: 'flex'}}>{renderFeedbackRating(rating)}</div>
                      <div style={{flex: 10, display: 'flex', paddingTop: 4}}>{rating.toFixed(1)} feedback</div>
                    </div>
                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', fontSize: 10, color: '#B6B7BB'}}>
                      <div style={{flex: 4, display: 'flex'}}><span class="material-icons" style={{fontSize: 20}}>date_range</span></div>
                      <div style={{flex: 10, display: 'flex', paddingTop: 4}}>{property.viewingsCount} showings</div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )
        })}
      </Row>
    </Container>
  );
}

export default Properties;
