import React, {useEffect, useState} from "react";
import {
  Card,
  CardHeader,
  FormCheckbox,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Container,
  Form,
  FormFeedback,
  Button,
  Alert
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import UserDetails from "../../components/user-profile/UserDetails";
import CompanyLogo from "../../components/user-profile/CompanyLogo";
import UserAccountDetails from "../../components/user-profile/UserAccountDetails";
import BusinessSettings from "../../components/user-profile/BusinessSettings";
import { MINUTE_OPTIONS, HOUR_OPTIONS } from "../../constants";

const ShowingDefaults = (props) => {
  const filterForms = forms => {
    return forms.filter(form => form.type === 'Qualification');
  }

  const [requiresQualification, setRequiresQualification] = useState(!!props.user.requiresQualification);
  const [agentOnlyViewing, setAgentOnlyViewing] = useState(props.user.agentOnlyViewing !== undefined ? !!props.user.agentOnlyViewing : true);
  const [forms, setForms] = useState(filterForms(props.forms));
  const [qualificationForm, setQualificationForm] = useState(props.user.qualificationForm || null);
  const [defaultViewingLength, setDefaultViewingLength] = useState(props.user.defaultViewingLength || 20);
  const [defaultViewingNotice, setDefaultViewingNotice] = useState(props.user.defaultViewingNotice || 1);
  const [confirmBy, setConfirmBy] = useState(props.user.confirmBy || 'agent');
  const [updatedUser, setUpdatedUser] = useState(false);

  useEffect(() => {
    document.title = 'Showing Defaults: Real Happy Dashboard';
    props.getLiveForms(props.user.id);
  }, [])

  useEffect(() => {
    setForms(filterForms(props.forms))
  }, [props.forms])

  useEffect(() => {
    if (props.updatedUser) {
      setUpdatedUser(true);
      setTimeout(() => setUpdatedUser(false), 10000);
    }
  }, [props.updatedUser]);

  

  return (
    <Container fluid className="main-content-container px-0">
      <Container fluid className="px-0">
        {updatedUser && (
          <Alert className="mb-0" theme={'success'}>
            <i className="fa fa-info mx-2"></i> Your showings defaults have been updated!
          </Alert>
        )}
      </Container>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Showing Defaults" subtitle="Settings" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
          <Col lg="6">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Qualification settings</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <FormCheckbox
                                toggle
                                checked={agentOnlyViewing}
                                onChange={() => setAgentOnlyViewing(!agentOnlyViewing)}
                              >
                                Require Agent-only requests?
                            </FormCheckbox>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="12" className="form-group">
                            <FormCheckbox
                                toggle
                                checked={requiresQualification}
                                onChange={() => setRequiresQualification(!requiresQualification)}
                              >
                                Requires qualification
                            </FormCheckbox>
                          </Col>
                        </Row>
                        {requiresQualification && (
                          <Row form>
                            <Col md="12" className="form-group">
                              <label htmlFor="feQualificationForm">Qualification form</label>
                              <FormSelect id="feQualificationForm" value={qualificationForm} onChange={event => setQualificationForm(event.target.value === '' ? null : event.target.value)} invalid={requiresQualification && !qualificationForm}>
                                <option value={''}>Select form</option>
                                {forms.map(form => {
                                  return (
                                    <option value={form.id}>{form.name}</option>
                                  )
                                })}
                              </FormSelect>
                              <FormFeedback>Please select a qualification form.</FormFeedback>
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Showing settings</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feDefaultViewingLength">Default showing length</label>
                            <FormSelect id="feConfirmBy" value={defaultViewingLength} onChange={event => setDefaultViewingLength(event.target.value)}>
                                {MINUTE_OPTIONS.map(item => {
                                  return (<option value={item.value.toString()}>{item.label}</option>)
                                })}
                            </FormSelect>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feDefaultViewingNotice">Notice required for showing request</label>
                            <FormSelect id="feConfirmBy" value={defaultViewingNotice} onChange={event => setDefaultViewingNotice(event.target.value)}>
                                {HOUR_OPTIONS().map(item => {
                                  return (<option value={item.value.toString()}>{item.label}</option>)
                                })}
                            </FormSelect>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
          <Col lg="6">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Confirmation settings</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feConfirmBy">Who must confirm showings?</label>
                            <Row>
                              <Col style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                                <div onClick={() => setConfirmBy('agent')} style={{cursor: 'pointer', width: 86, height: 100, border: confirmBy === 'agent' ? '1px solid #007bff' : '1px solid #e1e5eb', borderRadius: 5, marginRight: 16, textAlign: 'center', paddingTop: 14}}>
                                  <i class="material-icons" style={{fontSize: 40, color: confirmBy === 'agent' ? '#007bff' : '#5a6169'}}>folder_shared</i><br />
                                  <span style={{fontSize: 12, fontWeight: 'bold', color: confirmBy === 'agent' ? '#007bff' : '#5a6169'}}>Agent (me)</span>
                                </div>
                                <div onClick={() => setConfirmBy('owner')} style={{cursor: 'pointer', width: 86, height: 100, border: confirmBy === 'owner' ? '1px solid #007bff' : '1px solid #e1e5eb', borderRadius: 5, marginRight: 16, textAlign: 'center', paddingTop: 14}}>
                                  <i class="material-icons" style={{fontSize: 40, color: confirmBy === 'owner' ? '#007bff' : '#5a6169'}}>lightbulb</i><br />
                                  <span style={{fontSize: 12, fontWeight: 'bold', color: confirmBy === 'owner' ? '#007bff' : '#5a6169'}}>Owner</span>
                                </div>
                                <div onClick={() => setConfirmBy('none')} style={{cursor: 'pointer', width: 86, height: 100, border: confirmBy === 'none' ? '1px solid #007bff' : '1px solid #e1e5eb', borderRadius: 5, marginRight: 16, textAlign: 'center', paddingTop: 14}}>
                                  <i class="material-icons" style={{fontSize: 40, color: confirmBy === 'none' ? '#007bff' : '#5a6169'}}>rocket_launch</i><br />
                                  <span style={{fontSize: 12, fontWeight: 'bold', color: confirmBy === 'none' ? '#007bff' : '#5a6169'}}>Auto show</span>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
          <Col>
            <Card small className="mb-4">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Button
                        disabled={requiresQualification && !qualificationForm}
                        theme="accent"
                        onClick={() => props.updateUser({confirmBy, agentOnlyViewing, requiresQualification, qualificationForm, defaultViewingLength, defaultViewingNotice})}
                      >Update Showing Defaults</Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  )
};

export default ShowingDefaults;
