// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  loadingProperties: false,
  loadedProperties: false,
  properties: [],
  propertySearchTerm: '',
  addingProperty: false,
  addedProperty: false,
  property: null,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_PROPERTIES:
      return {
        ...state, loadingProperties: true, loadedProperties: false
      };
    case actionConstants.LOADED_PROPERTIES:
      return {
        ...state, loadingProperties: false, loadedProperties: true, properties: action.payload
      };
    case actionConstants.SET_PROPERTY_SEARCH_TERM:
      return {
        ...state, propertySearchTerm: action.payload
      };
    case actionConstants.ADDING_PROPERTY:
      return {
        ...state, addingProperty: true, addedProperty: false
      };
    case actionConstants.ADDED_PROPERTY:
      return {
        ...state, addingProperty: false, addedProperty: true, property: action.payload
      };  
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getProperty = ({
  loadingProperties, loadedProperties, properties, propertySearchTerm, addingProperty, addedProperty, property
}) => ({
  loadingProperties,
  loadedProperties,
  properties,
  propertySearchTerm,
  addingProperty,
  addedProperty,
  property,
});
