import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from "shards-react";
import {formatPhoneNumber} from "../../utils/tools";

const BusinessSettings = ({ user, title, updateUser, loadingUser }) => {
  const [company, setCompany] = useState(user.company || null);
  const [iso2] = useState(user.iso2);
  const [companyEmail, setCompanyEmail] = useState(user.companyEmail || null);
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState(user.companyPhoneNumber || null);
  const [referralEmail, setReferralEmail] = useState(user.referralEmail || null);

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feCompanyName">Company Name</label>
                    <FormInput
                      id="feCompanyName"
                      placeholder="Company Name"
                      value={company}
                      onChange={(event) => setCompany(event.target.value)}
                    />
                  </Col>
                </Row>
                <Row form>
                  {/* Email */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feCompanyEmail">Company Email</label>
                    <FormInput
                      type="email"
                      id="feCompanyEmail"
                      placeholder="Company Email Address"
                      value={companyEmail}
                      onChange={(event) => setCompanyEmail(event.target.value)}
                      autoComplete="companyEmail"
                    />
                  </Col>
                  <Col md="6" className="form-group">
                    <label htmlFor="feCompanyPhoneNumber">Company Phone Number</label>
                    <FormInput
                      type="tel"
                      id="feCompanyPhoneNumber"
                      placeholder="Company Phone number"
                      value={formatPhoneNumber(companyPhoneNumber, iso2) ? formatPhoneNumber(companyPhoneNumber, iso2) : companyPhoneNumber}
                      onChange={(event) => setCompanyPhoneNumber(event.target.value)}
                      autoComplete="companyPhoneNumber"
                    />
                  </Col>
                  <Col md="6" className="form-group">
                    <label htmlFor="feReferralEmail">Mortgage Referral Email</label>
                    <FormInput
                      type="email"
                      id="feReferralEmail"
                      placeholder="Mortgage Referral Email"
                      value={referralEmail}
                      onChange={(event) => setReferralEmail(event.target.value)}
                      autoComplete="referralEmail"
                    />
                  </Col>
                </Row>
                <Button
                  theme="accent"
                  onClick={() => updateUser({company, companyEmail, companyPhoneNumber, referralEmail})}
                >Update Business Settings</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  )
};

BusinessSettings.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

BusinessSettings.defaultProps = {
  title: "Business Settings"
};

export default BusinessSettings;
