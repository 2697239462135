import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
} from "shards-react";
import ReactCrop from 'react-image-crop';
import {getCroppedImg, uploadImageToStorage} from '../../utils/tools';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-activity/lib/Bounce/Bounce.css';

const AddPropertyImage = ({ user, addMedia, onClose, onUpload }) => {
  const [image, setImage] = useState();
  const [crop, setCrop] = useState({
    unit: '%',
    height: 50,
    width: 50,
  });
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageFile, setImageFile] = useState(false);

  const onImageLoaded = image => {
    setImageRef(image);
  };

  const onFileChange = event => {
    // Update the state
    setImageFile(URL.createObjectURL(event.target.files[0]));
    setImageLoaded(true);
  };

  return (
    <Form>
      <div className="text-center p-3">
        <div className="mb-3 mx-auto">
          {croppedImage && (
            <img
              src={croppedImage}
              alt={'Image'}
              width="110"
            />
          )}
        </div>
        <div>
          <strong className="text-muted d-block mb-2">
            {!imageLoaded ? 'Upload image' : 'Crop image'}
          </strong>
          {!imageLoaded && (
            <div>
              <div className="custom-file mb-3">
                <input type="file" className="custom-file-input" id="customFile" onChange={onFileChange}/>
                <label className="custom-file-label" htmlFor="customFile">
                  Choose file...
                </label>
              </div>
              <div>
                <Button
                  size={'sm'}
                  theme={"danger"}
                  onClick={() => onClose()}
                  >Cancel</Button>
              </div>
            </div>
          )}
          {imageLoaded && (
            <div className={'mb-4'}>
              <ReactCrop
                src={imageFile}
                crop={crop}
                onChange={newCrop => setCrop(newCrop)}
                onComplete={async (crop, percentCrop) => {
                  if (imageRef && crop) {
                    let croppedImageUrl = await getCroppedImg(imageRef, crop, 'output.jpg')
                    setCroppedImageBlob(croppedImageUrl);
                    let reader = new FileReader();
                    reader.readAsDataURL(croppedImageUrl);
                    reader.onloadend = function() {
                      let base64data = reader.result;
                      setCroppedImage(base64data)
                    };
                  }
                }}
                circularCrop={false}
                onImageLoaded={onImageLoaded}
              />
              <Button
                size={'sm'}
                className={'mr-2'}
                theme={"primary"}
                onClick={() => uploadImageToStorage(croppedImageBlob, 'media', (url) => {
                  setImage(url);
                  setImageLoaded(false);
                  setImageFile(null);
                  setImageRef(null);
                  onUpload(url);
                  onClose();
                })}
              >Save</Button>
              <Button
                size={'sm'}
                theme={"danger"}
                onClick={() => {
                  setImageLoaded(false);
                  setImageFile(null);
                  setCroppedImage(null);
                  setCroppedImageBlob(null);
                  setImageRef(null);
                }}
              >Cancel</Button>
            </div>
          )}
        </div>
      </div>
    </Form>
  )
};

AddPropertyImage.propTypes = {
  /**
   * The user details object.
   */
  user: PropTypes.object
};

AddPropertyImage.defaultProps = {
  userDetails: {
    name: "Sierra Brooks",
    logo: require("./../../images/avatars/0.jpg"),
    jobTitle: "Project Manager",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Description",
    metaValue:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?"
  }
};

export default AddPropertyImage;
