// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  loggingIn: false,
  loggedIn: false,
  loginFail: false,
  codeSent: false,
  checkingCode: false,
  codeFailed: false,
  codePassed: false,
  confirmResult: null,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOGGING_IN:
      return {
        ...state, loggingIn: true, loggedIn: false, loginFail: false,
      };
    case actionConstants.VERIFICATION_CODE_SENT:
      return {
        ...state, codeSent: true, checkingCode: false, codeFailed: false, confirmResult: action.payload,
      };
    case actionConstants.LOGIN_FAIL:
      return {
        ...state, loggingIn: false, loggedIn: false, loginFail: true,
      };
    case actionConstants.VERIFICATION_CHECKING:
      return {
        ...state, checkingCode: true, codeFailed: false, codePassed: false,
      };
    case actionConstants.VERIFICATION_PASSED:
      return {
        ...state, checkingCode: false, codeFailed: false, codePassed: true,
      };
    case actionConstants.VERIFICATION_FAILED:
      return {
        ...state, checkingCode: false, codeFailed: true, codePassed: false,
      };
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getLogin = ({loggingIn, loggedIn, loginFail, codeSent, checkingCode, codeFailed, confirmResult, codePassed}) => ({
  loggingIn,
  loggedIn,
  loginFail,
  codeSent,
  checkingCode,
  codeFailed,
  confirmResult,
  codePassed
});
