import EditProperty from './EditProperty';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getProperty, getContact, getForm} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user} = getUser(state);
  const {properties, propertySearchTerm, property} = getProperty(state);
  const {addingContact, addedContact, contacts, contact, loadedContacts, loadingContacts} = getContact(state);
  const {forms} = getForm(state);

  return {
    user,
    properties,
    propertySearchTerm,
    property,
    addingContact,
    addedContact,
    contacts,
    contact,
    loadedContacts,
    loadingContacts,
    forms,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditProperty);
