import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from "shards-react";
import {formatPhoneNumber} from "../../utils/tools";

const UserAccountDetails = ({ user, title, updateUser, loadingUser }) => {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [iso2] = useState(user.iso2);
  const [phoneNumber] = useState(user.phoneNumber);

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feFirstName">Name</label>
                    <FormInput
                      id="feName"
                      placeholder="Name"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </Col>
                </Row>
                <Row form>
                  {/* Email */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feEmail">Email</label>
                    <FormInput
                      type="email"
                      id="feEmail"
                      placeholder="Email Address"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      autoComplete="email"
                    />
                  </Col>
                  <Col md="6" className="form-group">
                    <label htmlFor="feEmail">Phone Number</label>
                    <FormInput
                      type="tel"
                      id="feEmail"
                      placeholder="Phone number"
                      disabled={true}
                      value={formatPhoneNumber(phoneNumber, iso2)}
                      autoComplete="phone"
                    />
                  </Col>
                </Row>
                <Button
                  theme="accent"
                  onClick={() => updateUser({name, email})}
                >Update Account</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  )
};

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Details"
};

export default UserAccountDetails;
