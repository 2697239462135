import firebase from 'firebase';
import * as actionConstants from '../actionConstants';

export const getUser = () => async dispatch => {
  dispatch({ type: actionConstants.LOADING_USER });
  let currentUser = await firebase.auth().currentUser;

  return firebase
    .firestore()
    .collection('users')
    .doc(currentUser.uid)
    .get()
    .then(doc => {
      dispatch({ type: actionConstants.LOADED_USER, payload: {id: doc.id, ...doc.data()}})
    })
    .catch(error => console.log(error))

};

export const getDisplayUser = (userId) => async dispatch => {
  dispatch({ type: actionConstants.LOADING_DISPLAY_USER });

  return firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .get()
    .then(doc => {
      dispatch({ type: actionConstants.LOADED_DISPLAY_USER, payload: {id: doc.id, ...doc.data()}})
    })
    .catch(error => console.log(error))

};

export const setUserAvatar = url => async dispatch => {
  let currentUser = await firebase.auth().currentUser;

  return firebase
    .firestore()
    .collection('users')
    .doc(currentUser.uid)
    .update({
      avatar: url,
    })
    .then(() => {
      dispatch({ type: actionConstants.SAVED_USER_AVATAR })
      dispatch(getUser())
    })
};

export const setCompanyLogo = url => async dispatch => {
  let currentUser = await firebase.auth().currentUser;

  return firebase
    .firestore()
    .collection('users')
    .doc(currentUser.uid)
    .update({
      companyLogo: url,
    })
    .then(() => {
      dispatch({ type: actionConstants.SAVED_COMPANY_LOGO })
      dispatch(getUser())
    })
};

export const updateUser = data => async dispatch => {
  let currentUser = await firebase.auth().currentUser;

  return firebase
    .firestore()
    .collection('users')
    .doc(currentUser.uid)
    .update(data)
    .then(() => {
      dispatch({ type: actionConstants.UPDATED_USER })
      dispatch(getUser())
    })
};

export const setUserPrimaryColor = color => async dispatch => {
  let currentUser = await firebase.auth().currentUser;

  return firebase
    .firestore()
    .collection('users')
    .doc(currentUser.uid)
    .update({
      primaryConsumerColor: color
    })
    .then(() => {
      dispatch({ type: actionConstants.SAVED_USER_PRIMARY_COLOR });
      dispatch(getUser())
    })
};
