import React, {useState} from "react";
import {Container, Row, Col, Card, CardHeader, CardBody} from "shards-react";

import PageTitle from "../../components/common/PageTitle";

const Property = (props) => {

  const getIdFromPathname = (pathname) => {
    return pathname.substr(pathname.indexOf('/property-support/') + 18);
  };

  const [selectedProperty] = useState(props.searchPropertyResults.find(property => property.id === getIdFromPathname(props.location.pathname)));

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Property detail" subtitle="Super user tools" md="12" className="ml-sm-auto mr-sm-auto" />
      </Row>
      {selectedProperty && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">{selectedProperty.address}</h6>
              </CardHeader>
              <CardBody className="p-4 pb-3">
                <p>
                  ID: {selectedProperty.id}<br />
                  User: <a href={'/user-support/' + selectedProperty.user}>{selectedProperty.user}</a><br />
                  Address: {selectedProperty.address}<br />
                  Availability: {selectedProperty.available ? 'Yes': 'No'}<br />
                  Booking Link: <a href={selectedProperty.viewingLink} target={'_blank'} rel="noopener noreferrer">{selectedProperty.viewingLink}</a><br />
                </p>
                <h6>Extra property data</h6>
                <p>
                {Object.entries(selectedProperty).map(entry => {
                  if (!(entry[1] instanceof Object)) {
                    return (<span><span style={{fontWeight: 'bold'}}>{entry[0]}:</span> {entry[1]}<br/></span>)
                  }
                  return null;
                })}
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  )
};

export default Property;
