import Contacts from './Contacts';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getContact} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user} = getUser(state);
  const {contacts, contactsSearchTerm, sendingContactsCsv, sentContactsCsv, addedContact, contact, updatedContact} = getContact(state);

  return {
    user,
    contacts,
    contactsSearchTerm,
    sendingContactsCsv,
    sentContactsCsv,
    addedContact,
    contact,
    updatedContact
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Contacts);
