import firebase from 'firebase';
import axios from 'axios';

// For Google libnumber
const PNF = require('google-libphonenumber').PhoneNumberFormat;
// Get an instance of `PhoneNumberUtil`.
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

/**
 * Parse the given phone number
 *
 * @param phoneNumber
 * @param country
 * @returns {*}
 */
export const parsePhonenumber = (phoneNumber, country) =>
  phoneUtil.parseAndKeepRawInput(phoneNumber, country);

/**
 * Return the phone number in E164 format
 *
 * @param phoneNumber
 * @param country
 * @returns {string|*}
 */
export const getE164 = (phoneNumber, country) =>
  phoneUtil.format(
    phoneUtil.parseAndKeepRawInput(phoneNumber, country),
    PNF.E164,
  );

  /**
 * Is valid phone number
 *
 * @param phoneNumber
 * @param country
 * @returns {string|*}
 */
export const isValidPhoneNumber = (phoneNumber, country) => {
  let number = phoneNumber;
  try {
    number = phoneUtil.parseAndKeepRawInput(number, country.toUpperCase());
  } catch(err) {
    return false;
  }

  return phoneUtil.isValidNumberForRegion(number, country.toUpperCase());
}

/**
 * Return the region code from given number
 *
 * @param phoneNumber
 * @returns {null}
 */
export const getRegionCode = phoneNumber =>
  phoneUtil.getRegionCodeForNumber(phoneUtil.parse(phoneNumber));

/**
 * Format the given phone number to the country format
 *
 * @param phoneNumber
 * @param country
 * @returns {string|*}
 */
export const formatPhoneNumber = (phoneNumber, country) => {
  try {
    phoneUtil.parse(phoneNumber, country);
  } catch (err) {
    return '';
  }
  return phoneUtil.formatInOriginalFormat(
    phoneUtil.parse(phoneNumber, country),
    country,
  );
};

/**
 * Strip format chars out of phone number
 *
 * @param phoneNumber
 * @returns {string}
 */
export const stripPhoneNumber = phoneNumber => {
  return phoneNumber.replace(/[\(\)\-\s]/g, '');
};


/**
 * Return property "text in" code
 *
 * @param property
 * @returns {string}
 */
export const getTextCodeFromProperty = property => {
  if (!property) {
    return '----';
  }
  return property.textCode;
};

/**
 * Return the incoming text number based on country
 *
 * @param user
 * @returns {string}
 */
export const getIncomingTextCode = user => {
  switch (user.iso2) {
    case 'us':
      return '(646) 846 7755';
    case 'gb':
      return '07723 459860';
  }
};

/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 */
export const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      blob.name = fileName;
      resolve(blob);
    }, 'image/jpeg', 1);
    canvas.toDataURL();
  });
};

/**
 * Upload image to Firebase storage
 *
 * @param image
 * @param targetPath
 * @param callback
 */
export const uploadImageToStorage = (image, targetPath, callback) => {
  const storageRef = firebase.storage().ref(
    `/${targetPath}/${firebase.auth().currentUser.uid}_${Date.now()}.jpg`,
  );
  storageRef
    .put(image)
    .then(async snapshot => {
      if (snapshot.state === 'success') {
        let downloadUrl = await storageRef.getDownloadURL();
        callback(downloadUrl)
      }
    })
    .catch(error => console.log(error));
};

/**
 * Translation table
 *
 * @param word
 * @param locale
 * @param capitalise
 * @returns {*}
 */
 export const translation = (word, locale, capitalise) => {
  let translationTable = {
    viewing: {
      gb: 'viewing',
      us: 'showing',
      ca: 'showing',
    },
    vendor: {
      gb: 'vendor',
      us: 'owner',
      ca: 'owner',
    },
    enquire: {
      gb: 'enquire',
      us: 'inquire',
      ca: 'inquire',
    },
    town: {
      gb: 'town',
      us: 'city',
      ca: 'city',
    },
    county: {
      gb: 'county',
      us: 'state',
      ca: 'state',
    },
    postcode: {
      gb: 'postcode',
      us: 'zip',
      ca: 'zip',
    },
    colour: {
      gb: 'colour',
      us: 'color',
      ca: 'color',
    },
    ft: {
      au: 'm',
      nz: 'm',
      es: 'm',
    },
  };
  if (translationTable[word] && translationTable[word][locale]) {
    word = translationTable[word][locale];
  }
  return capitalise ? word.substr(0, 1).toUpperCase() + word.substring(1) : word;
};

export const getDefaultFeedbackFormName = (user, property, feedbackForms, viewing) => {
  if (viewing && viewing.feedbackFormName) {
    return viewing.feedbackFormName;
  }
  if (property && property.defaultFeedbackForm) {
    return feedbackForms.find(form => form.id === property.defaultFeedbackForm).name;
  }
  if (user && user.defaultFeedbackForm) {
    return feedbackForms.find(form => form.id === user.defaultFeedbackForm).name;
  }
  if (feedbackForms && feedbackForms.length > 0) {
    return feedbackForms.filter(form => !form.openhouse)[0].name;
  }
  return '';
};

export const getDefaultFeedbackForm = (user, property, feedbackForms, viewing) => {
  if (viewing && viewing.feedbackForm) {
    return viewing.feedbackForm;
  }
  if (property && property.defaultFeedbackForm) {
    return feedbackForms.find(form => form.id === property.defaultFeedbackForm);
  }
  if (user && user.defaultFeedbackForm) {
    return feedbackForms.find(form => form.id === user.defaultFeedbackForm);
  }
  if (feedbackForms && feedbackForms.length > 0) {
    return feedbackForms.filter(form => !form.openhouse)[0];
  }
  return null;
};

export const getDefaultOpenhouseFormName = (user, property, feedbackForms) => {
  if (property && property.defaultOpenhouseForm) {
    return feedbackForms.find(form => form.id === property.defaultOpenhouseForm).name;
  }
  if (user && user.defaultOpenhouseForm) {
    return feedbackForms.find(form => form.id === user.defaultOpenhouseForm).name;
  }
  if (feedbackForms && feedbackForms.length > 0) {
    return feedbackForms.filter(form => form.openhouse)[0].name;
  }
  return '';
};

export const getDefaultOpenhouseForm = (user, property, feedbackForms) => {
  if (property && property.defaultOpenhouseForm) {
    return feedbackForms.find(form => form.id === property.defaultOpenhouseForm);
  }
  if (user && user.defaultOpenhouseForm) {
    return feedbackForms.find(form => form.id === user.defaultOpenhouseForm);
  }
  if (feedbackForms && feedbackForms.length > 0) {
    return feedbackForms.filter(form => form.openhouse)[0];
  }
  return null;
};

export const generateDynamicLink = async link => {
  const data = {
    dynamicLinkInfo: {
      domainUriPrefix: 'https://getrealhappy.com/links',
      link,
    },
    suffix: {
      option: 'SHORT',
    },
  };
  return await axios.post(
    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyArf3xqmQnbH_1p5H5v1dSvVZPmqhwvYRk',
    data,
  ).then(resp => resp.data.shortLink);
};
