/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Col,
  Form,
  FormFeedback,
  Button,
  FormInput,
  FormTextarea,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import {useParams} from "react-router-dom";
import Select from 'react-select';
import { MINUTE_OPTIONS, HOUR_OPTIONS } from "../../constants";
import PageTitle from "../../components/common/PageTitle";
import PropertyImageRow from "../../components/edit-property/PropertyImageRow";
import AddPropertyImage from "../../components/edit-property/AddPropertyImage";
import EditContact from "../EditContact";

const EditProperty = (props) => {

  const filterQualificationForms = forms => {
    return forms.filter(form => form.type === 'Qualification');
  }

  const filterFeedbackForms = forms => {
    return forms.filter(form => form.type === 'Feedback');
  }

  let {propertyId} = useParams();
  const [tab, setTab] = useState(1);
  const [property, setProperty] = useState(props.properties.find(property => property.id === propertyId));
  const [defaultContact, setDefaultContact] = useState(null);
  const [additionalContact, setAdditionalContact] = useState(null);
  const [contacts, setContacts] = useState(props.contacts);
  const [contactsArray, setContactsArray] = useState([]);
  const [description, setDescription] = useState(null);
  const [propertyListingId, setPropertyListingId] = useState(null);
  const [price, setPrice] = useState(null);
  const [beds, setBeds] = useState(null);
  const [baths, setBaths] = useState(null);
  const [sqFootage, setSqFootage] = useState(null);
  const [viewingDetails, setViewingDetails] = useState(null);
  const [defaultViewingLength, setDefaultViewingLength] = useState(null);
  const [defaultViewingNotice, setDefaultViewingNotice] = useState(null);
  const [requestFeedback, setRequestFeedback] = useState(null);
  const [feedbackForm, setFeedbackForm] = useState(null);
  const [requiresQualification, setRequiresQualification] = useState(null);
  const [agentOnlyViewing, setAgentOnlyViewing] = useState(null);
  const [qualificationForm, setQualificationForm] = useState(null);
  const [feedbackForms, setFeedbackForms] = useState(filterFeedbackForms(props.forms));
  const [qualificationForms, setQualificationForms] = useState(filterQualificationForms(props.forms));
  const [confirmBy, setConfirmBy] = useState('agent');
  const [media, setMedia] = useState([]);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [showAddDefaultContact, setShowAddDefaultContact] = useState(false);
  const [showAddAdditionalContact, setShowAddAdditionalContact] = useState(false);

  useEffect(() => {
    props.getLiveProperties(props.user.id)
    props.getLiveContacts(props.user.id)
  }, [])

  useEffect(() => {
    props.getLiveContacts(props.user.id)
    if (property) {
      setDefaultContact(property.defaultContact || null)
      setAdditionalContact(property.additionalContact || null)
      setDescription(property.description || null)
      setPropertyListingId(property.propertyListingId || null)
      setPrice(property.price || null)
      setBeds(property.beds || null)
      setBaths(property.baths || null)
      setSqFootage(property.sqFootage || null)
      setViewingDetails(property.viewingDetails || null)
      setDefaultViewingLength(property.defaultViewingLength)
      setDefaultViewingNotice(property.defaultViewingNotice)
      setRequestFeedback(!!property.requestFeedback)
      setFeedbackForm(property.feedbackForm || null)
      setRequiresQualification(!!property.requiresQualification)
      setAgentOnlyViewing(property.agentOnlyViewing !== undefined ? !!property.agentOnlyViewing : true)
      setQualificationForm(property.qualificationForm || null)
      setConfirmBy(property.confirmBy || null)
      setMedia(property.media || [])
    }
  }, [property])

  useEffect(() => {
    setProperty(props.properties.find(property => property.id === propertyId))
  }, [props.properties])

  useEffect(() => {
    setContacts(props.contacts)
    setContactsArray(contacts.map(contact => {
      return {label: contact.name, value: contact.id}
    }))
  }, [props.contacts])

  const getTabTitle = () => {
    switch (tab) {
      case 1:
        return 'Contacts';
      case 2:
        return 'Information';
      case 3:
        return "Settings";
      case 4:
        return 'Images';      
    }
  }

  const sortedImages = () => {
    return media.sort((a, b) => b.cover - a.cover)
  }

  const makeCoverImage = (property, image) => {
    props.makeCoverImage(property, image)
  }

  const deleteImage = (property, image) => {
    props.removeMedia(property, image)
  }

  const renderTabOne = () => (
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="feDefaultContact">Owner</label>
                  <Row>
                    <Col xs='8'>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={contactsArray.find(contact => contact.value === defaultContact)}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="defaultContact"
                        options={contactsArray}
                        onChange={option => {
                          setDefaultContact(option ? option.value : null)
                        }}
                      />
                    </Col>
                    <Col xs='4' className='text-right'>
                      <Button
                        theme="accent"
                        onClick={() => setShowAddDefaultContact(true)}
                      >Add Contact</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {showAddDefaultContact && (
                <Row>
                  <EditContact
                    removeTitles={true}
                    onSave={contact => {
                      contactsArray.push({ value: contact.id, label: contact.name})
                      setDefaultContact(contact.id)
                      setShowAddDefaultContact(false)
                    }}
                    onCancel={() => setShowAddDefaultContact(false)}
                  />
                </Row>  
              )}    
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="feAdditionalContact">Additional Owner</label>
                  <Row>
                    <Col xs='8'>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={contactsArray.find(contact => contact.value === additionalContact)}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="additionalContact"
                        options={contactsArray}
                        onChange={option => {
                          setAdditionalContact(option ? option.value : null)
                        }}
                      />
                    </Col>
                    <Col xs='4' className='text-right'>
                      <Button
                        theme="accent"
                        onClick={() => setShowAddAdditionalContact(true)}
                      >Add Contact</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {showAddAdditionalContact && (
                <Row>
                  <EditContact
                    removeTitles={true}
                    onSave={contact => {
                      contactsArray.push({ value: contact.id, label: contact.name})
                      setAdditionalContact(contact.id)
                      setShowAddAdditionalContact(false)
                    }}
                    onCancel={() => setShowAddAdditionalContact(false)}
                  />
                </Row>  
              )}                
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  )

  const renderTabTwo = () => (
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="feDescription">Public instructions or description</label>
                  <FormTextarea
                    id='feDescription'
                    onChange={event => setDescription(event.target.value)}
                    placeholder='Let anyone viewing this booking link see more information or instructions'
                    value={description}
                  />
                </Col>
              </Row>               
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="fePropertyListingId">Property ID</label>
                  <FormInput
                    id='fePropertyListingId'
                    onChange={event => setPropertyListingId(event.target.value)}
                    placeholder='Property ID'
                    value={propertyListingId}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="fePrice">Price</label>
                  <InputGroup className="mb-3">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>$</InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      id='fePrice'
                      onChange={event => setPrice(event.target.value)}
                      placeholder='Price'
                      value={price}
                      type={'number'}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row form>
                <Col md="4" className="form-group">
                  <label htmlFor="feBeds"># of Beds</label>
                  <FormInput
                      id='feBeds'
                      onChange={event => setBeds(event.target.value)}
                      placeholder='Beds'
                      value={beds}
                      type={'number'}
                    />
                </Col>
                <Col md="4" className="form-group">
                  <label htmlFor="feBaths"># of Baths</label>
                  <FormInput
                      id='feBaths'
                      onChange={event => setBaths(event.target.value)}
                      placeholder='Baths'
                      value={baths}
                      type={'number'}
                    />
                </Col>
                <Col md="4" className="form-group">
                  <label htmlFor="feSqFootage">Sq Footage</label>
                  <FormInput
                      id='feSqFootage'
                      onChange={event => setSqFootage(event.target.value)}
                      placeholder='Sq Footage'
                      value={sqFootage}
                      type={'number'}
                    />
                </Col>
              </Row>             
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  )

  const renderTabThree = () => (
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="feViewingDetails">Showing instructions</label>
                  <FormTextarea
                    id='feViewingDetails'
                    onChange={event => setViewingDetails(event.target.value)}
                    placeholder='Add instructions that will be sent to the confirmed showing contact such as the lockbox details.'
                    value={viewingDetails}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="feConfirmBy">Who must confirm showings?</label>
                  <Row>
                    <Col style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                      <div onClick={() => setConfirmBy('agent')} style={{cursor: 'pointer', width: 86, height: 100, border: confirmBy === 'agent' ? '1px solid #007bff' : '1px solid #e1e5eb', borderRadius: 5, marginRight: 16, textAlign: 'center', paddingTop: 14}}>
                        <i className="material-icons" style={{fontSize: 40, color: confirmBy === 'agent' ? '#007bff' : '#5a6169'}}>folder_shared</i><br />
                        <span style={{fontSize: 12, fontWeight: 'bold', color: confirmBy === 'agent' ? '#007bff' : '#5a6169'}}>Agent (me)</span>
                      </div>
                      <div onClick={() => setConfirmBy('owner')} style={{cursor: 'pointer', width: 86, height: 100, border: confirmBy === 'owner' ? '1px solid #007bff' : '1px solid #e1e5eb', borderRadius: 5, marginRight: 16, textAlign: 'center', paddingTop: 14}}>
                        <i className="material-icons" style={{fontSize: 40, color: confirmBy === 'owner' ? '#007bff' : '#5a6169'}}>lightbulb</i><br />
                        <span style={{fontSize: 12, fontWeight: 'bold', color: confirmBy === 'owner' ? '#007bff' : '#5a6169'}}>Owner</span>
                      </div>
                      <div onClick={() => setConfirmBy('none')} style={{cursor: 'pointer', width: 86, height: 100, border: confirmBy === 'none' ? '1px solid #007bff' : '1px solid #e1e5eb', borderRadius: 5, marginRight: 16, textAlign: 'center', paddingTop: 14}}>
                        <i className="material-icons" style={{fontSize: 40, color: confirmBy === 'none' ? '#007bff' : '#5a6169'}}>rocket_launch</i><br />
                        <span style={{fontSize: 12, fontWeight: 'bold', color: confirmBy === 'none' ? '#007bff' : '#5a6169'}}>Auto show</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="feDefaultViewingLength">Default showing length</label>
                  <FormSelect id="feConfirmBy" value={defaultViewingLength} onChange={event => setDefaultViewingLength(event.target.value)}>
                      {MINUTE_OPTIONS.map(item => {
                        return (<option value={item.value.toString()}>{item.label}</option>)
                      })}
                  </FormSelect>
                </Col>
              </Row>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="feDefaultViewingNotice">Notice required for showing request</label>
                  <FormSelect id="feConfirmBy" value={defaultViewingNotice} onChange={event => setDefaultViewingNotice(event.target.value)}>
                      {HOUR_OPTIONS().map(item => {
                        return (<option value={item.value.toString()}>{item.label}</option>)
                      })}
                  </FormSelect>
                </Col>
              </Row>
              <Row form>
                <Col md="12" className="form-group">
                  <FormCheckbox
                      toggle
                      checked={requestFeedback}
                      onChange={() => setRequestFeedback(!requestFeedback)}
                    >
                      Request feedback after showings?
                  </FormCheckbox>
                </Col>
              </Row>
              {requestFeedback && (
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feFeedbackForm">Feedback form</label>
                    <FormSelect id="feFeedbackForm" value={feedbackForm} onChange={event => setFeedbackForm(event.target.value === '' ? null : event.target.value)} invalid={requestFeedback && !feedbackForm}>
                      <option value={''}>Select form</option>
                      {feedbackForms.map(form => {
                        return (
                          <option value={form.id}>{form.name}</option>
                        )
                      })}
                    </FormSelect>
                    <FormFeedback>Please select a feedback form.</FormFeedback>
                  </Col>
                </Row>
              )}
              <Row form>
                <Col md="12" className="form-group">
                  <FormCheckbox
                      toggle
                      checked={agentOnlyViewing}
                      onChange={() => setAgentOnlyViewing(!agentOnlyViewing)}
                    >
                      Require Agent-only requests?
                  </FormCheckbox>
                </Col>
              </Row>
              <Row form>
                <Col md="12" className="form-group">
                  <FormCheckbox
                      toggle
                      checked={requiresQualification}
                      onChange={() => setRequiresQualification(!requiresQualification)}
                    >
                      Screen requests?
                  </FormCheckbox>
                </Col>
              </Row>
              {requiresQualification && (
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feQualificationForm">Qualification form</label>
                    <FormSelect id="feQualificationForm" value={qualificationForm} onChange={event => setQualificationForm(event.target.value === '' ? null : event.target.value)} invalid={requiresQualification && !qualificationForm}>
                      <option value={''}>Select form</option>
                      {qualificationForms.map(form => {
                        return (
                          <option value={form.id}>{form.name}</option>
                        )
                      })}
                    </FormSelect>
                    <FormFeedback>Please select a qualification form.</FormFeedback>
                  </Col>
                </Row>
              )}    
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  )

  const renderTabFour = () => (
    <div>
      <CardHeader className="border-bottom" style={{paddingTop: 12, paddingBottom: 12}}>
        <Row>
          <Col style={{paddingTop: 4}}>
            <h6 className="m-0">All images</h6>
          </Col>
          <Col className='text-right'>
            <Button
              theme="accent"
              onClick={() => setShowImageUpload(!showImageUpload)}
            >Add image</Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="p-0 pb-3">
        {showImageUpload && (
          <Container>
            <Row className="border-bottom">
              <Col>
                <AddPropertyImage onUpload={(url) => props.addMedia(property, url, media)} onClose={() => setShowImageUpload(false)} user={props.user} />
              </Col>
            </Row>
          </Container>
        )}
        <table className="table mb-0">
          <thead className="bg-light">
            <tr>
              <th scope="col" className="border-0 text-center">
                Image
              </th>
              <th scope="col" className="border-0">
                Type
              </th>
              <th scope="col" className="border-0">
                Uploaded by
              </th>
              <th scope="col" className="border-0">
                
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedImages() && sortedImages().map(image => (
              <PropertyImageRow
                image={image}
                user={props.user}
                onMakeCover={() => makeCoverImage(property, image)}
                onDelete={() => deleteImage(property, image)}
              />
            ))}
          </tbody>
        </table>
      </CardBody>
    </div>
  )

  const renderTabContent = () => {
    switch (tab) {
      case 1:
        return renderTabOne();
      case 2:
        return renderTabTwo();
      case 3:
        return renderTabThree();
      case 4:
        return renderTabFour();      
    }
  }

  return (
    property ? (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col xs="12" sm="8">
            <PageTitle sm="12" title={getTabTitle()} subtitle={"Edit " + property.address} className="text-sm-left" />
          </Col>
        </Row>

        {/* First Row of Posts */}
        <Row>
          <Col md="4" className="mb-4">
            <Card small className="card-post card-post--1">
              <CardHeader className="border-bottom">
                Property Wizard
              </CardHeader>
              <CardBody className='p-0'>
                <ListGroup small flush className="list-group-small">
                  <ListGroupItem className="px-3" onClick={() => setTab(1)} style={{cursor: 'pointer', paddingBottom: 16}}>
                    <p className="text-semibold text-fiord-blue mb-0">
                      <i className="material-icons" style={{paddingRight: 10, fontSize: 20}}>person_add_alt</i><span style={tab === 1 ? {color: '#007bff'} : {}}> Contacts</span> {tab === 1 && (
                        <i className="material-icons" style={{paddingLeft: 5, fontSize: 16, color: '#007bff'}}>check</i>
                      )}
                      <i className="material-icons" style={{fontSize: 18, textAlign: 'right', display: 'block', marginTop: -24}}>more_horiz</i>
                    </p>
                    {tab === 1 && (
                      <p className="ml-auto text-left text-semibold text-reagent-gray mb-0 mt-2">
                        When added, your owner will be sent a link to their personal property portal via SMS and Email where they can manage their showings.
                      </p>
                    )}
                  </ListGroupItem>
                  <ListGroupItem className="px-3" onClick={() => setTab(2)} style={{cursor: 'pointer', paddingBottom: 16}}>
                    <p className="text-semibold text-fiord-blue mb-0">
                      <i className="material-icons" style={{paddingRight: 10, fontSize: 20}}>border_color</i><span style={tab === 2 ? {color: '#007bff'} : {}}> Information</span> {tab === 2 && (
                        <i className="material-icons" style={{paddingLeft: 5, fontSize: 16, color: '#007bff'}}>check</i>
                      )}
                      <i className="material-icons" style={{fontSize: 18, textAlign: 'right', display: 'block', marginTop: -24}}>more_horiz</i>
                    </p>
                    {tab === 2 && (
                      <p className="ml-auto text-left text-semibold text-reagent-gray mb-0 mt-2">
                        Make your booking page stand out with additional publicly visible information.
                      </p>
                    )}
                  </ListGroupItem>
                  <ListGroupItem className="px-3" onClick={() => setTab(3)} style={{cursor: 'pointer', paddingBottom: 16}}>
                    <p className="text-semibold text-fiord-blue mb-0">
                      <i className="material-icons" style={{paddingRight: 10, fontSize: 20}}>settings</i><span style={tab === 3 ? {color: '#007bff'} : {}}> Settings</span> {tab === 3 && (
                        <i className="material-icons" style={{paddingLeft: 5, fontSize: 16, color: '#007bff'}}>check</i>
                      )}
                      <i className="material-icons" style={{fontSize: 18, textAlign: 'right', display: 'block', marginTop: -24}}>more_horiz</i>
                    </p>
                    {tab === 3 && (
                      <p className="ml-auto text-left text-semibold text-reagent-gray mb-0 mt-2">
                        Set your booking page to fit your the specific requirements for this property.
                      </p>
                    )}
                  </ListGroupItem>
                  <ListGroupItem className="px-3" onClick={() => setTab(4)} style={{cursor: 'pointer', paddingBottom: 16}}>
                    <p className="text-semibold text-fiord-blue mb-0">
                      <i className="material-icons" style={{paddingRight: 10, fontSize: 20}}>image</i><span style={tab === 4 ? {color: '#007bff'} : {}}> Images</span> {tab === 4 && (
                        <i className="material-icons" style={{paddingLeft: 5, fontSize: 16, color: '#007bff'}}>check</i>
                      )}
                      <i className="material-icons" style={{fontSize: 18, textAlign: 'right', display: 'block', marginTop: -24}}>more_horiz</i>
                    </p>
                    {tab === 4 && (
                      <p className="ml-auto text-left text-semibold text-reagent-gray mb-0 mt-2">
                        Add or edit photos of the property.
                      </p>
                    )}
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col md="8" className="mb-4">
            <Card className={'mb-0'}>
              {renderTabContent()}
            </Card>
            
            <Card style={{marginTop: -1, marginBottom: 0}}>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            {tab === 1 && (
                              <Button
                                theme="accent"
                                onClick={() => {
                                  props.updateProperty(property, {defaultContact, additionalContact})
                                  setTab(2)
                                }}
                              >Next or skip</Button>
                            )}
                            {tab === 2 && (
                              <Button
                                theme="accent"
                                onClick={() => {
                                  props.updateProperty(property, {description, propertyListingId, price, beds, baths, sqFootage})
                                  setTab(3)
                                }}
                              >Next or skip</Button>
                            )}
                            {tab === 3 && (
                              <Button
                                theme="accent"
                                onClick={() => {
                                  props.updateProperty(property, {viewingDetails, defaultViewingLength, defaultViewingNotice, requestFeedback, feedbackForm, agentOnlyViewing, requiresQualification, qualificationForm, confirmBy})
                                  setTab(4)
                                }}
                                disabled={(requestFeedback && !feedbackForm) || (requiresQualification && !qualificationForm)}
                              >Next or skip</Button>
                            )}
                            {tab === 4 && (
                              <Button
                                theme="accent"
                                onClick={() => window.location.href='/properties'}
                              >Finished</Button>
                            )}
                          </Col>
                        </Row>                        
                      </Form>
                    </Col>
                    <Col></Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    ) : null
  );
}

export default EditProperty;
