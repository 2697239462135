import firebase from 'firebase';
import {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import { getDefaultFeedbackForm, getDefaultOpenhouseForm, generateDynamicLink } from '../utils/tools';
import {WEB_APP_URL} from '../environmentVariables';
import * as actionConstants from '../actionConstants';

/**
 * Generate random text code
 *
 * @returns {string}
 */
 const generateTextCode = () => {
  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  let code = '';
  code = code + letters[getRandomInt(23)];
  code = code + letters[getRandomInt(23)];
  code = code + (parseInt(getRandomInt(899), 10) + 100);
  return code;
};

const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const setPropertySearchTerm = term => dispatch => {
  dispatch({type: actionConstants.SET_PROPERTY_SEARCH_TERM, payload: term})
}

export const getProperties = (userId) => async dispatch => {
  dispatch({ type: actionConstants.LOADING_PROPERTIES });

  return firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('properties')
    .get()
    .then(querySnapshot => {
      Promise.all(querySnapshot.docs.map(async (doc) => {
        let cover = await doc.ref.collection('media').where('cover', '==', true).get().then(querySnapshot => querySnapshot.size > 0 ? querySnapshot.docs[0].data().url : null).catch(error => console.log(error))
        let media = await doc.ref.collection('media').get().then(querySnapshot => {
          let images = [];
          querySnapshot.forEach(doc => {
            if (doc && doc.data()) {
              images.push({id: doc.id, ...doc.data()})
            }
          })
          return images;
        }).catch(error => console.log(error))
        return {id: doc.id, media: media, cover: cover, ...doc.data()}
      })).then((results) => {
        dispatch({ type: actionConstants.LOADED_PROPERTIES, payload: results})
      });
    })
    .catch(error => console.log(error))
};

export const getLiveProperties = (userId) => dispatch => {
  dispatch({ type: actionConstants.LOADING_PROPERTIES });

  return firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('properties')
    .onSnapshot(querySnapshot => {
      Promise.all(querySnapshot.docs.map(async (doc) => {
        let cover = await doc.ref.collection('media').where('cover', '==', true).get().then(querySnapshot => querySnapshot.size > 0 ? querySnapshot.docs[0].data().url : null).catch(error => console.log(error))
        let media = await doc.ref.collection('media').get().then(querySnapshot => {
          let images = [];
          querySnapshot.forEach(doc => {
            if (doc && doc.data()) {
              images.push({id: doc.id, ...doc.data()})
            }
          })
          return images;
        }).catch(error => console.log(error))
        let viewingsCount = await firebase.firestore().collection('users').doc(userId).collection('viewings').where('property', '==', doc.id).get().then(querySnapshot => querySnapshot.size)
        let feedback = [];
        if (viewingsCount > 0) {
          feedback = await firebase.firestore().collection('users').doc(userId).collection('feedback').where('property', '==', doc.id).where('rating', '>', 0).get().then(querySnapshot => {
            let feedbackItems = []
            querySnapshot.forEach(doc => {
              if (doc && doc.data()) {
                feedbackItems.push({id: doc.id, ...doc.data()})
              }
            })
            return feedbackItems;
          })
        }
        return {id: doc.id, media: media, cover: cover, viewingsCount: viewingsCount, feedback: feedback, ...doc.data()}
      })).then((results) => {
        dispatch({ type: actionConstants.LOADED_PROPERTIES, payload: results})
      });

    });
};

/**
 * Add property
 * @param {*} property 
 * @param {*} selectedContact 
 * @param {*} from 
 * @returns 
 */
export const addProperty = (property, selectedContact, from) => {
  return async (dispatch, getState) => {
    dispatch({type: actionConstants.ADDING_PROPERTY});
    let defaultFeedbackForm = getDefaultFeedbackForm(getState().user.user, null, getState().form.forms)
    let defaultOpenhouseForm = getDefaultOpenhouseForm(getState().user.user, null, getState().form.forms)
    let currentUser = await firebase.auth().currentUser;
    let propertyDetails = {
      ...property,
      feedbackSent: 0,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      defaultFeedbackForm: defaultFeedbackForm ? defaultFeedbackForm.id : null,
      defaultOpenhouseForm: defaultOpenhouseForm ? defaultOpenhouseForm.id : null,
      //defaultContact: selectedContact,
      textCode: generateTextCode(),
      available: true,
      availabilityChangedBy: currentUser.uid,
      availabilityChangedByName: currentUser.displayName,
      availabilityChangedOn: Date.now(),
      from: from || null,
      confirmBy: !selectedContact && getState().user.user.confirmBy === 'owner' ? 'agent' : getState().user.user.confirmBy,
      defaultViewingLength: getState().user.user.defaultViewingLength || 25,
      defaultViewingNotice: getState().user.user.defaultViewingNotice || 1,
      requiresQualification: getState().user.user.requiresQualification || false,
      agentOnlyViewing: getState().user.user.agentOnlyViewing || true,
      confirmBy: getState().user.user.confirmBy || 'agent',
      qualificationForm: getState().user.user.qualificationForm || null,
      viewingDaySettings: {
        'Monday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
        'Tuesday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
        'Wednesday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
        'Thursday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
        'Friday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
        'Saturday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
        'Sunday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
      },
      viewingDetails: ''
    };
    if (!propertyDetails.location) {
      let geoPlace = await geocodeByAddress(propertyDetails.address).then(results => results[0]).catch(error => console.log(error))
      let location = await getLatLng(geoPlace).then(result => result).catch(error => console.log(error))
      propertyDetails.location = {
        latitude: location.lat,
        longitude: location.lng,
      };
      propertyDetails.addressComponents = geoPlace.address_components;
      propertyDetails.placeId = geoPlace.place_id;
    }
    return firebase
      .firestore()
      .collection('users')
      .doc(currentUser.uid)
      .collection('properties')
      .add(propertyDetails)
      .then(async doc => {
        let newProperty = {
          id: doc.id,
          user: currentUser.uid,
          ...propertyDetails,
        }
        dispatch({
          type: actionConstants.ADDED_PROPERTY,
          payload: newProperty,
        });
        let viewingLink = await generateDynamicLink(WEB_APP_URL + '/b/' + currentUser.uid + '/' + doc.id);
        let viewingDetailsLink = await generateDynamicLink(WEB_APP_URL + '/b/' + currentUser.uid + '/' + doc.id + '/details');
        doc.update({
          viewingLink,
          viewingDetailsLink
        })
        let smsPropertyCodeUpdate = await dispatch(smsPropertyListing(newProperty, true, newProperty.textCode, currentUser))
        window.location.href = '/edit-property/' + doc.id
      });
  };
};

/**
 * Switch on/off SMS property listing
 *
 * @param property
 * @param value
 * @param textCode
 * @returns {Function}
 */
 const smsPropertyListing = (property, value, textCode, currentUser) => {
  return (dispatch, getState) => {
    if (value) {
        return firebase
        .firestore()
        .collection('smsPropertyList')
        .where('textCode', '==', textCode)
        .get()
        .then(querySnapshot => {
          if (querySnapshot.size === 0) {
            return firebase
              .firestore()
              .collection('smsPropertyList')
              .add({
                property: property.id,
                propertyAddress: property.address,
                textCode: textCode,
                user: currentUser.uid,
                senderName: getState().user.user.useCompanyNameInComms
                  ? getState().user.user.companyNameToUse
                  : currentUser.displayName,
                senderAvatar: getState().user.user.avatar,
                senderPhoneNumber: getState().user.user.phoneNumber,
                senderIso2: getState().user.user.iso2,
                teamName:
                  getState().user.teamName || getState().user.user.name || null,
                companyName:
                  getState().user.companyName || getState().user.user.company || null,
                teamReferralEmail:
                  getState().user.teamReferralEmail ||
                  getState().user.user.referralEmail ||
                  null,
              });
          }
          return true;
        });
    } else {
        return firebase
        .firestore()
        .collection('smsPropertyList')
        .where('textCode', '==', textCode)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            doc.ref.delete();
          });
        });
    }
  };
};

/**
 * Update property
 * @param {*} property 
 * @param {*} selectedContact 
 * @param {*} from 
 * @returns 
 */
 export const updateProperty = (property, data, callback) => {
  return async (dispatch, getState) => {
    dispatch({type: actionConstants.UPDATING_PROPERTY});
    let currentUser = await firebase.auth().currentUser;
    let propertyDetails = {...data};
    
    return firebase
      .firestore()
      .collection('users')
      .doc(currentUser.uid)
      .collection('properties')
      .doc(property.id)
      .update(propertyDetails)
      .then(async (doc) => {
        let newProperty = {
          id: property.id,
          user: currentUser.uid,
          ...property,
          ...propertyDetails,
        }
        dispatch({
          type: actionConstants.UPDATED_PROPERTY,
          payload: newProperty,
        });
        let smsPropertyCodeUpdate = await dispatch(smsPropertyListing(newProperty, true, newProperty.textCode, currentUser))
        callback && callback()
      });
  };
};

/**
 * Set cover image for property
 *
 * @param property
 * @param image
 * @returns {function(*): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
 export const makeCoverImage = (property, image) => {
  return (dispatch, getState) => {
    return firebase
      .firestore()
      .collection('users')
      .doc(getState().user.user.id)
      .collection('properties')
      .doc(property.id)
      .collection('media')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(async doc => {
          if (doc.id === image.id) {
            await doc.ref.update({cover: true});
          } else {
            await doc.ref.update({cover: false});
          }
        });
        dispatch(getProperties(getState().user.user.id))
      });
  };
};

/**
 * Add property media
 *
 * @param property
 * @param url
 * @param media
 * @returns {function(*): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
 export const addMedia = (property, url, media) => {
  return (dispatch, getState) => {
    let cover = media && media.length === 0;
    let uploadedOn = Date.now()
    return firebase
      .firestore()
      .collection('users')
      .doc(getState().user.user.id)
      .collection('properties')
      .doc(property.id)
      .collection('media')
      .add({
        url,
        cover,
        uploadedOn
      })
      .then(() => {
        dispatch(getProperties(getState().user.user.id));
      });
  };
};

/**
 * Remove media from property
 *
 * @param property
 * @param id
 * @returns {function(*): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
 export const removeMedia = (property, image) => {
  return (dispatch, getState) => {
    return firebase
      .firestore()
      .collection('users')
      .doc(getState().user.user.id)
      .collection('properties')
      .doc(property.id)
      .collection('media')
      .doc(image.id)
      .delete()
      .then(() => {
        dispatch(getProperties(getState().user.user.id));
      });
  };
};

