import React from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ActionCreators} from '../../../actions';
import {getProperty} from '../../../reducers/rootReducer';

const NavbarPropertySearch  = ({propertySearchTerm, setPropertySearchTerm}) => (
  <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
    <InputGroup seamless className="ml-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>
          <i className="material-icons">search</i>
        </InputGroupText>
      </InputGroupAddon>
      <FormInput
        className="navbar-search"
        placeholder="Search properties..."
        onChange={event => setPropertySearchTerm(event.target.value)}
        value={propertySearchTerm}
      />
    </InputGroup>
  </Form>
);

const mapStateToProps = state => {
  const {propertySearchTerm} = getProperty(
    state,
  );

  return {
    propertySearchTerm,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarPropertySearch);
