import React, {useEffect, useState} from "react";
import {Container, Row, Col, Alert} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import UserDetails from "../../components/user-profile/UserDetails";
import CompanyLogo from "../../components/user-profile/CompanyLogo";
import UserAccountDetails from "../../components/user-profile/UserAccountDetails";
import BusinessSettings from "../../components/user-profile/BusinessSettings";

const UserProfile = (props) => {
  const [savedUserAvatar, setSavedUserAvatar] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(false);
  const [savedUserPrimaryColor, setSavedUserPrimaryColor] = useState(false);
  const [savedCompanyLogo, setSavedCompanyLogo] = useState(false);

  useEffect(() => {
    document.title = 'User Profile: Real Happy Dashboard';
  }, [])

  useEffect(() => {
    if (props.savedUserAvatar) {
      setSavedUserAvatar(true);
      setTimeout(() => setSavedUserAvatar(false), 10000);
    }
  }, [props.savedUserAvatar]);

  useEffect(() => {
    if (props.updatedUser) {
      setUpdatedUser(true);
      setTimeout(() => setUpdatedUser(false), 10000);
    }
  }, [props.updatedUser]);

  useEffect(() => {
    if (props.savedUserPrimaryColor) {
      setSavedUserPrimaryColor(true);
      setTimeout(() => setSavedUserPrimaryColor(false), 10000);
    }
  }, [props.savedUserPrimaryColor]);

  useEffect(() => {
    if (props.savedCompanyLogo) {
      setSavedCompanyLogo(true);
      setTimeout(() => setSavedCompanyLogo(false), 10000);
    }
  }, [props.savedCompanyLogo]);

  return (
    <Container fluid className="main-content-container px-0">
      <Container fluid className="px-0">
        {savedUserAvatar && (
          <Alert className="mb-0" theme={'success'}>
            <i className="fa fa-info mx-2"></i> Your avatar has been updated!
          </Alert>
        )}
        {updatedUser && (
          <Alert className="mb-0" theme={'success'}>
            <i className="fa fa-info mx-2"></i> Your details have been updated!
          </Alert>
        )}
        {savedUserPrimaryColor && (
          <Alert className="mb-0" theme={'success'}>
            <i className="fa fa-info mx-2"></i> Your primary color choice has been updated!
          </Alert>
        )}
        {savedCompanyLogo && (
          <Alert className="mb-0" theme={'success'}>
            <i className="fa fa-info mx-2"></i> Your company logo has been updated!
          </Alert>
        )}
      </Container>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="User Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
          <Col lg="4">
            <UserDetails
              user={props.user}
              loadingUser={props.loadingUser}
              setUserAvatar={props.setUserAvatar}
              setUserPrimaryColor={props.setUserPrimaryColor}
            />
            <CompanyLogo
              user={props.user}
              loadingUser={props.loadingUser}
              setCompanyLogo={props.setCompanyLogo}
            />
          </Col>
          <Col lg="8">
            <UserAccountDetails
              user={props.user}
              loadingUser={props.loadingUser}
              updateUser={props.updateUser}
            />
            <BusinessSettings
              user={props.user}
              loadingUser={props.loadingUser}
              updateUser={props.updateUser}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  )
};

export default UserProfile;
