import firebase from 'firebase';
import * as actionConstants from '../actionConstants';
import {getUser} from './userActions';
import {getProperties} from './propertyActions';
import { getE164 } from "../utils/tools";

/**
 * Use phone number to login to app
 *
 * @param phoneNumber
 * @param reCaptcha
 * @param iso2
 * @returns {Function}
 */
export const authenticateWithSMS = (phoneNumber, reCaptcha, iso2) => {
  return dispatch => {
    dispatch({type: actionConstants.LOGGING_IN});
    phoneNumber = getE164(phoneNumber.replace(/[^0-9]+/g, ''), iso2);
    firebase.auth()
      .signInWithPhoneNumber(phoneNumber, reCaptcha)
      .then(confirmResult => {
        dispatch({
          type: actionConstants.VERIFICATION_CODE_SENT,
          payload: confirmResult,
        });
      })
      .catch(error => {
        dispatch({type: actionConstants.LOGIN_FAIL, payload: error});
      });
  };
};

/**
 * Check the verification code that was send to the user
 *
 * @param code
 * @returns {Function}
 */
export const checkCode = code => {
  return (dispatch, getState) => {
    dispatch({type: actionConstants.VERIFICATION_CHECKING});
    getState()
      .login.confirmResult.confirm(code)
      .then(user => {
        if (user) {
          dispatch(finishLogin());
        }
      })
      .catch(error => {
        dispatch({type: actionConstants.VERIFICATION_FAILED});
      });
  };
};

export const finishLogin = () => {
  return async (dispatch, getState) => {
    let currentUser = await firebase.auth().currentUser;
    dispatch({type: actionConstants.LOADING_USER_DATA});
    let user = await firebase
      .firestore()
      .collection('users')
      .doc(currentUser.uid)
      .get()
      .then(doc => {
        return {id: doc.id, ...doc.data()};
      });

    // let properties = await firebase
    //   .firestore()
    //   .collection('users')
    //   .doc(currentUser.uid)
    //   .collection('properties')
    //   .get()
    //   .then(querySnapshot => {
    //     let properties = [];
    //     querySnapshot.forEach(doc => {
    //       properties.push({id: doc.id, ...doc.data()})
    //     });
    //     return properties;
    //   });
    //
    // dispatch({ type: actionConstants.LOADED_PROPERTIES, payload: properties});
    dispatch(getProperties(user.id));
    dispatch({ type: actionConstants.LOADED_USER, payload: user});
  }
};
