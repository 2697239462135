import React, {useState} from "react";
import {
  Container,
  Col,
  Row
} from "shards-react";

let moment = require('moment')

const PropertyImageRow = ({image, user, onMakeCover, onDelete}) => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <tr>
      <td>
        <div
          className="card-post__image"
          style={{ backgroundImage: `url(${image.url})`, backgroundSize: 'contain', minHeight: "4rem" }}
        ></div>
      </td>
      <td>{image.cover ? 'Cover Image' : 'Image'}</td>
      <td>{user.name}<br /><span style={{fontSize: 12, color: 'gray'}}>{image.uploadedOn ? moment(image.uploadedOn).format('MMMM D, YYYY') + ' at ' + moment(image.uploadedOn).format('h:mm a') : 'February 1, 2021 at 8:43 pm'}</span></td>
      <td>
        <div style={{position: 'relative', textAlign: 'right'}}>
          <i className="material-icons" style={{fontSize: 18, cursor: 'pointer'}} onClick={() => setShowMenu(!showMenu)}>more_horiz</i>
          {showMenu && (
            <div style={{position: 'absolute', zIndex: 100, backgroundColor: '#FFFFFF', border: '2px solid #EFEFEF', right: 60, top: 0, width: 220, textAlign: 'left'}}>
              <Container style={{width: '100%'}}>
                <Row className='p-2' style={{borderBottom: '1px solid #EFEFEF', cursor: 'pointer'}} onClick={() => {
                    onMakeCover();
                    setShowMenu(!showMenu);
                }}>
                  <Col xs={2}><i className="material-icons">star_border</i></Col>
                  <Col xs={10}>Make cover image</Col>
                </Row>
                <Row className='p-2' style={{cursor: 'pointer'}} onClick={() => {
                    onDelete();
                    setShowMenu(!showMenu);
                }}>
                  <Col xs={2}><i className="material-icons">delete_outline</i></Col>
                  <Col xs={10}>Delete image</Col>
                </Row>
              </Container>
              <div></div>
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}

export default PropertyImageRow;