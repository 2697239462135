import firebase from 'firebase';
import * as actionConstants from '../actionConstants';

export const getLiveForms = (userId) => dispatch => {
  dispatch({ type: actionConstants.LOADING_FORMS });

  return firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('feedbackForms')
    .onSnapshot(querySnapshot => {
      let forms = [];
      querySnapshot.forEach(doc => {
        forms.push({ id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_FORMS, payload: forms})
    });
};
