import ShowingDefaults from './ShowingDefaults';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getForm} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user, updatedUser} = getUser(state);
  const {forms} = getForm(state);

  return {
    user,
    forms,
    updatedUser
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShowingDefaults);
