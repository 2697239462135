import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Container,
  Button,
  ListGroup,
  ListGroupItem,
  Progress,
  Row,
} from "shards-react";
import ReactCrop from 'react-image-crop';
import Bounce from 'react-activity/lib/Bounce';
import {getCroppedImg, uploadImageToStorage} from '../../utils/tools';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-activity/lib/Bounce/Bounce.css';

const UserDetails = ({ user, setUserAvatar, loadingUser, setUserPrimaryColor }) => {
  const [avatar, setAvatar] = useState(user.avatar);
  const [name, setName] = useState(user.name);
  const [editingAvatar, setEditingAvatar] = useState(false);
  const [crop, setCrop] = useState({
    aspect: 1 / 1,
    height: 50,
    width: 50,
  });
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageFile, setImageFile] = useState(false);
  const [primaryConsumerColor, setPrimaryConsumerColor] = useState(user.primaryConsumerColor);

  // useEffect(() => {
  //   console.log(user.avatar)
  //   console.log(avatar)
  //   if (user.avatar !== avatar) {
  //     setAvatar(user.avatar)
  //   }
  // }, [user.avatar]);

  useEffect(() => {
    if (user.name !== name) {
      setName(user.name)
    }
  }, [user.name]);

  const onImageLoaded = image => {
    setImageRef(image);
  };

  const onFileChange = event => {
    // Update the state
    setImageFile(URL.createObjectURL(event.target.files[0]));
    setImageLoaded(true);
  };

  return (
    <Card small className="mb-4 pt-3">
      <CardHeader className="border-bottom text-center">
        <div className="mb-3 mx-auto">
          {/*loadingUser && (
            <div style={{height: 110, width: 110, textAlign: 'center'}}>
              <Bounce />
            </div>
          )*/}
          {(croppedImage || avatar) && (
            <img
              className="rounded-circle"
              src={croppedImage || avatar}
              alt={name}
              width="110"
            />
          )}
        </div>
        {!editingAvatar ? (
          <p><a onClick={() => setEditingAvatar(true)} style={{cursor: 'pointer'}}>Click to edit avatar</a></p>
        ) : (
          <div>
            <strong className="text-muted d-block mb-2">
              {!imageLoaded ? 'Upload avatar' : 'Crop avatar'}
            </strong>
            {!imageLoaded && (
              <div>
                <div className="custom-file mb-3">
                  <input type="file" className="custom-file-input" id="customFile" onChange={onFileChange}/>
                  <label className="custom-file-label" htmlFor="customFile">
                    Choose file...
                  </label>
                </div>
                <div>
                  <Button
                    size={'sm'}
                    theme={"danger"}
                    onClick={() => setEditingAvatar(false)}
                    >Cancel</Button>
                </div>
              </div>
            )}
            {imageLoaded && (
              <div className={'mb-4'}>
                <ReactCrop
                  src={imageFile}
                  crop={crop}
                  onChange={newCrop => setCrop(newCrop)}
                  onComplete={async (crop, percentCrop) => {
                    if (imageRef && crop) {
                      let croppedImageUrl = await getCroppedImg(imageRef, crop, 'output.jpg')
                      setCroppedImageBlob(croppedImageUrl);
                      let reader = new FileReader();
                      reader.readAsDataURL(croppedImageUrl);
                      reader.onloadend = function() {
                        let base64data = reader.result;
                        setCroppedImage(base64data)
                      };
                    }
                  }}
                  circularCrop={true}
                  onImageLoaded={onImageLoaded}
                />
                <Button
                  size={'sm'}
                  className={'mr-2'}
                  theme={"primary"}
                  onClick={() => uploadImageToStorage(croppedImageBlob, 'avatars', (url) => {
                    setUserAvatar(url);
                    setAvatar(url);
                    setEditingAvatar(false);
                    setImageLoaded(false);
                    setImageFile(null);
                    setImageRef(null);
                  })}
                >Save</Button>
                <Button
                  size={'sm'}
                  theme={"danger"}
                  onClick={() => {
                    setEditingAvatar(false);
                    setImageLoaded(false);
                    setImageFile(null);
                    setCroppedImage(null);
                    setCroppedImageBlob(null);
                    setImageRef(null);
                  }}
                >Cancel</Button>
              </div>
            )}
          </div>
        )}
        {!editingAvatar && (
          <div>
            <h4 className="mb-0">{name}</h4>
            <span className="text-muted d-block mb-2">{user.contactType}</span>
          </div>
        )}
      </CardHeader>
      {!editingAvatar && (
        <Container>
          <strong className="text-muted d-block mb-3 text-center mt-3">
            Select your primary color
          </strong>
          <Row className={'mb-3'}>
            <div className={'col-3'}>
              <div onClick={() => setPrimaryConsumerColor('#000000')} style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#000000', textAlign: 'center', paddingTop: 12}}>
                {primaryConsumerColor === '#000000' && (
                  <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                )}
              </div>
            </div>
            <div onClick={() => setPrimaryConsumerColor('#727679')} className={'col-3'}>
              <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#727679', textAlign: 'center', paddingTop: 12}}>
                {primaryConsumerColor === '#727679' && (
                  <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                )}
              </div>
            </div>
            <div onClick={() => setPrimaryConsumerColor('#C5AB5E')} className={'col-3'}>
              <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#C5AB5E', textAlign: 'center', paddingTop: 12}}>
                {primaryConsumerColor === '#C5AB5E' && (
                  <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                )}
              </div>
            </div>
            <div onClick={() => setPrimaryConsumerColor('#C23742')} className={'col-3'}>
              <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#C23742', textAlign: 'center', paddingTop: 12}}>
                {primaryConsumerColor === '#C23742' && (
                  <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                )}
              </div>
            </div>
          </Row>
          <Row className={'mb-3'}>
            <div onClick={() => setPrimaryConsumerColor('#458102')} className={'col-3'}>
              <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#458102', textAlign: 'center', paddingTop: 12}}>
                {primaryConsumerColor === '#458102' && (
                  <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                )}
              </div>
            </div>
            <div onClick={() => setPrimaryConsumerColor('#0A2345')} className={'col-3'}>
              <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#0A2345', textAlign: 'center', paddingTop: 12}}>
                {primaryConsumerColor === '#0A2345' && (
                  <i className={'fa fa-check text-center'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                )}
              </div>
            </div>
            <div onClick={() => setPrimaryConsumerColor('#63285B')} className={'col-3'}>
              <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#63285B', textAlign: 'center', paddingTop: 12}}>
                {primaryConsumerColor === '#63285B' && (
                  <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                )}
              </div>
            </div>
            <div onClick={() => setPrimaryConsumerColor('#2D79CF')} className={'col-3'}>
              <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#2D79CF', textAlign: 'center', paddingTop: 12}}>
                {primaryConsumerColor === '#2D79CF' && (
                  <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                )}
              </div>
            </div>
          </Row>
          <Row className={'mb-3'}>
            <div onClick={() => setPrimaryConsumerColor('#032169')} className={'col-3'}>
              <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#032169', textAlign: 'center', paddingTop: 12}}>
                {primaryConsumerColor === '#032169' && (
                  <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                )}
              </div>
            </div>
            <div onClick={() => setPrimaryConsumerColor('#2D862D')} className={'col-3'}>
              <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#2D862D', textAlign: 'center', paddingTop: 12}}>
                {primaryConsumerColor === '#2D862D' && (
                  <i className={'fa fa-check text-center'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                )}
              </div>
            </div>
            <div onClick={() => setPrimaryConsumerColor('#002349')} className={'col-3'}>
              <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#002349', textAlign: 'center', paddingTop: 12}}>
                {primaryConsumerColor === '#002349' && (
                  <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                )}
              </div>
            </div>
            <div onClick={() => setPrimaryConsumerColor('#492E00')} className={'col-3'}>
              <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#492E00', textAlign: 'center', paddingTop: 12}}>
                {primaryConsumerColor === '#492E00' && (
                  <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                )}
              </div>
            </div>
          </Row>
          {primaryConsumerColor !== user.primaryConsumerColor && (
            <div className={'text-center mb-3'}>
              <Button
                size={'sm'}
                className={'mr-2'}
                theme={"primary"}
                onClick={() => setUserPrimaryColor(primaryConsumerColor)}
              >Save</Button>
              <Button
                size={'sm'}
                theme={"danger"}
                onClick={() => setPrimaryConsumerColor(user.primaryConsumerColor)}
              >Cancel</Button>
            </div>
          )}
        </Container>
      )}
    </Card>
  )
};

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  user: PropTypes.object
};

UserDetails.defaultProps = {
  userDetails: {
    name: "Sierra Brooks",
    avatar: require("./../../images/avatars/0.jpg"),
    jobTitle: "Project Manager",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Description",
    metaValue:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?"
  }
};

export default UserDetails;
