/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component } from "react";
import firebase from "firebase";
import { getUserLocale } from 'get-user-locale';
import {
  Container,
  Row,
  Col,
  Card,
  Button, CardHeader, Form, FormInput, FormFeedback
} from "shards-react";
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import { isValidPhoneNumber } from '../../utils/tools';

const language = (getUserLocale() && getUserLocale().length === 5) ? getUserLocale() : 'en_US';
const locale = language.substr(-2).toLowerCase();

class Login extends Component {
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.user && this.props.user !== nextProps.user) {
      window.location.href = '/';
    }
  }

  componentDidMount() {
    setTimeout(() => {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('reCaptchaField', {
        size: 'invisible',
        badge: 'inline',
      });
    }, 300);
  }

  isValidCode() {
    return this.state.code.replace(/[^0-9]+/g, '').length === 6
  }

  state = {
    phoneNumber: '',
    code: '',
    iso2: locale,
    isValidPhoneNumber: false,
    isValidCode: false,
  };

  renderPhoneNumberEntry() {
    return (
      <Row>
        <Container fluid className="px-10">
          <Col>
            <strong className="text-muted d-block mb-2 mt-2">Enter phone number</strong>
            <Form>
              <Row form>
                <Col md="12" className="form-group">
                  <Row>
                    <Col style={{maxWidth: 95, marginTop: -16, paddingRight: 0}}>
                      <ReactFlagsSelect
                        defaultCountry={this.state.iso2.toUpperCase()}
                        showSelectedLabel={false}
                        onSelect={(countryCode) => this.setState({iso2: countryCode.toLowerCase()}, () => console.log(this.state))}
                        searchable={true}
                        selectedSize={24}
                      />
                    </Col>
                    <Col style={{paddingLeft: 0}}>
                      <FormInput
                        value={this.state.phoneNumber}
                        placeholder="Phone number"
                        required
                        onChange={event => this.setState({phoneNumber: event.target.value, isValidPhoneNumber: isValidPhoneNumber(event.target.value, this.state.iso2)}, () => console.log(isValidPhoneNumber(this.state.phoneNumber, this.state.iso2)))}
                        invalid={!this.state.isValidPhoneNumber && this.state.phoneNumber.length > 8}
                      />
                      <FormFeedback>Please enter a valid phone number.</FormFeedback>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row form>
                <Col md={'12'} className={'form-group'}>
                  <Button
                    onClick={() => this.props.authenticateWithSMS(this.state.phoneNumber, window.recaptchaVerifier, this.state.iso2)}
                    theme="primary"
                    className="mb-2 mr-1"
                    disabled={!this.state.isValidPhoneNumber}
                  >
                    Start
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md={'12'}>
                  <div id={'reCaptchaField'} className="center-block" />
                </Col>
              </Row>
            </Form>
          </Col>
        </Container>
      </Row>
    )
  }

  renderCodeEntry() {
    return (
      <Row>
        <Container fluid className="px-10">
          <Col>
            <strong className="text-muted d-block mb-2 mt-2">Enter code</strong>
            <Form>
              <Row form>
                <Col md="12" className="form-group">
                  <FormInput
                    value={this.state.code}
                    placeholder="Code"
                    required
                    onChange={event => this.setState({code: event.target.value})}
                    valid={this.isValidCode()}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md={'12'} className={'form-group'}>
                  <Button
                    onClick={() => this.props.checkCode(this.state.code)}
                    theme="primary"
                    className="mb-2 mr-1"
                    disabled={!this.isValidCode()}
                  >
                    Sign in
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md={'12'} className={'pb-4'}>
                  <div id={'reCaptchaField'} className="center-block" />
                </Col>
              </Row>
            </Form>
          </Col>
        </Container>
      </Row>
    )
  }

  render() {
    return (
      <Container fluid className="px-0">
        <Row>
          <Col md="6" className="mb-4 mt-4 mx-auto text-center">
            <img
              id="main-logo"
              className="d-inline-block"
              src={require("../../images/realhappylogo_small_grey.png")}
              style={{maxWidth: 300, maxHeight: 100}}
              alt="Real Happy Dashboard"
            />
          </Col>
        </Row>
        <Row>
          <Col md="6" className="mb-4 mx-auto">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Sign in</h6>
              </CardHeader>
              {this.props.codeSent ? (
                this.renderCodeEntry()
              ) : (
                this.renderPhoneNumberEntry()
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Login;
