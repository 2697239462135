// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  loadingForms: false,
  loadedForms: false,
  forms: [],
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_FORMS:
      return {
        ...state, loadingForms: true, loadedForms: false
      };
    case actionConstants.LOADED_FORMS:
      return {
        ...state, loadingForms: false, loadedForms: true, forms: action.payload
      };
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getForm = ({
  loadingForms, loadedForms, forms,
}) => ({
  loadingForms,
  loadedForms,
  forms,
});
