/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect, useRef} from "react";
import {
  Alert,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Col,
  Form,
  FormFeedback,
  Button,
  FormInput,
  FormTextarea,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import {useParams} from "react-router-dom";
import { getUserLocale } from 'get-user-locale';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import { isValidPhoneNumber, formatPhoneNumber } from '../../utils/tools';
import PageTitle from "../../components/common/PageTitle";

const language = (getUserLocale() && getUserLocale().length === 5) ? getUserLocale() : 'en_US';
const locale = language.substr(-2).toLowerCase();

const EditContact = (props) => {
  let {contactId} = useParams();
  const [contact, setContact] = useState(props.contacts.find(contact => contact.id === contactId));
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactType, setContactType] = useState('lead');
  const [iso2, setIso2] = useState(locale);
  const [contacts, setContacts] = useState(props.contacts);
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [validName, setValidName] = useState(null);
  const [validEmail, setValidEmail] = useState(true);
  const [addedContact, setAddedContact] = useState(false);
  const params = (new URL(document.location)).searchParams;
  const returnTo = params.get('return-to')
  const userFlag = useRef();

  useEffect(() => {
    props.getLiveContacts(props.user.id)
  }, [])

  useEffect(() => {
    if (contact) {
      setPhoneNumber(contact.phoneNumber ? formatPhoneNumber(contact.phoneNumber, contact.iso2) : '')
      setName(contact.name || '')
      setEmail(contact.email || '')
      setContactType(contact.contactType || 'lead')
      setIso2(contact.iso2 || locale)
      setValidPhoneNumber(!!isValidPhoneNumber(contact.phoneNumber, contact.iso2))
      setValidName(contact.name.length > 2)
      setValidEmail(() => validateEmail(contact.email))
      userFlag.current.updateSelected((contact.iso2 || locale).toUpperCase())
      console.log(contact)
    }
  }, [contact])

  useEffect(() => {
    if (contactId) {
      setContact(props.contacts.find(contact => contact.id === contactId))
    }
  }, [props.contacts, contactId])

  useEffect(() => {
    setContacts(props.contacts)
  }, [props.contacts])

  useEffect(() => {
    if (props.addedContact) {
      setAddedContact(true);
      setTimeout(() => setAddedContact(false), 10000);
    }
  }, [props.addedContact]);

  const validateEmail = (email) => {
    if (email && email.length > 0) {
      return (/^\w+([.\+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
    }
    return true
  }

  return (
    <Container fluid className="main-content-container px-0">
      {/*addedContact && (
        <Alert className="mb-0" theme={'success'}>
          <i className="fa fa-info mx-2"></i> {name} has been succesfully added!
        </Alert>
      )*/}
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        {!props.removeTitles && (
          <Row noGutters className="page-header py-4">
            <Col xs="12" sm="8">
              <PageTitle sm="12" title={'Add Contact'} subtitle={'Contacts'} className="text-sm-left" />
            </Col>
          </Row>
        )}
        {/* First Row of Posts */}
        <Row>
          <Col className="mb-4">
            <Card className={'mb-0'}>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feDefaultContact">Mobile number</label>
                            <Row>
                              <Col style={{maxWidth: 95, marginTop: -16, paddingRight: 0}}>
                                <ReactFlagsSelect
                                  ref={userFlag}
                                  defaultCountry={iso2.toUpperCase()}
                                  selected={iso2.toUpperCase()}
                                  showSelectedLabel={false}
                                  onSelect={(countryCode) => setIso2(countryCode.toLowerCase())}
                                  searchable={true}
                                  selectedSize={24}
                                />
                              </Col>
                              <Col style={{paddingLeft: 0}}>
                                <FormInput
                                  value={phoneNumber}
                                  placeholder="Mobile number"
                                  required
                                  onChange={event => {
                                    setPhoneNumber(event.target.value);
                                    setValidPhoneNumber(isValidPhoneNumber(event.target.value, iso2))
                                  }}
                                  invalid={!validPhoneNumber && phoneNumber.length > 8}
                                />
                                <FormFeedback>Please enter a valid mobile number.</FormFeedback>
                              </Col>
                            </Row>
                          </Col>
                        </Row>               
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feContactName">Full name</label>
                            <FormInput
                              id='feContactName'
                              onChange={event => setName(event.target.value)}
                              placeholder='Full name'
                              value={name}
                              invalid={validName === false}
                              onBlur={() => setValidName(name.length > 2)}
                            />
                            <FormFeedback>Please enter a valid name.</FormFeedback>
                          </Col>
                        </Row>               
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feContactEmail">Email address</label>
                            <FormInput
                              id='feContactEmail'
                              type={'email'}
                              onChange={event => setEmail(event.target.value)}
                              placeholder='Email address'
                              value={email}
                              invalid={validEmail === false}
                              onBlur={() => setValidEmail(() => validateEmail(email))}
                            />
                            <FormFeedback>Please enter a valid email address.</FormFeedback>
                          </Col>
                        </Row>               
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feContactType">Type</label>
                            <FormSelect id="feConfirmBy" value={contactType} onChange={event => setContactType(event.target.value)}>
                              <option value={'agent'}>Agent</option>
                              <option value={'other'}>Other</option>
                              <option value={'client'}>Client</option>
                              <option value={'lead'}>Lead</option>
                            </FormSelect>
                          </Col>
                        </Row>               
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                          <Button
                            className="mr-1"
                            theme="secondary"
                            onClick={() => props.onCancel ? props.onCancel() : window.location.href='/contacts'}
                          >Cancel</Button>
                          <Button
                            theme="accent"
                            disabled={!validPhoneNumber || !validName || !validEmail}
                            onClick={() => {
                              if (!!contact) {
                                props.editContact(contact, {phoneNumber, name, email, contactType, iso2}, (contact) => props.onSave ? props.onSave(contact) : window.location.href='/contacts')
                              } else {
                                props.addContact({phoneNumber, name, email, contactType, iso2}, (contact) => props.onSave ? props.onSave(contact) : window.location.href='/contacts')
                              }
                            }}
                          >Save</Button>
                          </Col>
                        </Row>                        
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default EditContact;
