export const MINUTE_OPTIONS = [
  { value: 10, label: '10 minutes'},
  { value: 15, label: '15 minutes'},
  { value: 20, label: '20 minutes'},
  { value: 25, label: '25 minutes'},
  { value: 30, label: '30 minutes'},
  { value: 35, label: '35 minutes'},
  { value: 40, label: '40 minutes'},
  { value: 45, label: '45 minutes'},
  { value: 60, label: '60 minutes'},
]

const generateHours = () => {
  let hours = [];
  let i = 1;
  while(i < 73) {
    hours.push({ value: i, label: i + ' hour' + (i>1 ? 's' : '')})
    i++;
  }
  return hours;
}

// export const HOUR_OPTIONS = [];
export const HOUR_OPTIONS = generateHours;