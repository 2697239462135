export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyArf3xqmQnbH_1p5H5v1dSvVZPmqhwvYRk",
  authDomain: "realhappy-b433b.firebaseapp.com",
  databaseURL: "https://realhappy-b433b.firebaseio.com",
  projectId: "realhappy-b433b",
  storageBucket: "realhappy-b433b.appspot.com",
  messagingSenderId: "358840887594",
  appId: "1:358840887594:web:a9a2f8040c17f2cc97d653",
  measurementId: "G-J6S0ZE8BZJ"
};
export const WEBSITE_URL = 'https://realhappy.net';
export const WEB_DASHBOARD_URL = 'https://admin.realhappy.net';
export const WEB_APP_URL = 'https://getrealhappy.com';
