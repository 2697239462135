import UserProfile from './UserProfile';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user, loadingUser, savedUserAvatar, updatedUser, savedUserPrimaryColor, savedCompanyLogo} = getUser(state);

  return {
    user,
    loadingUser,
    savedUserAvatar,
    updatedUser,
    savedUserPrimaryColor,
    savedCompanyLogo,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfile);
