/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect, useRef} from "react";
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Col,
  Form,
  FormFeedback,
  Button,
  FormInput,
} from "shards-react";
import GooglePlacesAutocomplete, {geocodeByPlaceId, getLatLng} from 'react-google-places-autocomplete';
import ReactFlagsSelect from 'react-flags-select-bootstrap';
import {getTextCodeFromProperty, getIncomingTextCode, translation} from "../../utils/tools";

import PageTitle from "../../components/common/PageTitle";

const AddProperty = (props) => {

  const [property, setProperty] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [zip, setZip] = useState(null);
  const [iso2, setIso2] = useState(props.user.iso2)
  const addressSelector = useRef(null);

  const setAddressFromSearch = async place => {
    if (place) {
      let geoPlace = await geocodeByPlaceId(place.value.place_id).then(results => results[0]).catch(error => console.log(error))
      let location = await getLatLng(geoPlace).then(result => result).catch(error => console.log(error))
      setProperty({
        address: place.label,
        addressComponents: geoPlace.address_components,
        placeID: place.value.place_id,
        types: geoPlace.types || null,
        placeCode: geoPlace.plus_code || null,
        name: place.value.structured_formatting.main_text,
        location: {
          latitude: location.lat,
          longitude: location.lng,
        }
      })
    } else {
      setProperty(null);
    }
  }

  const generateAddressString = () => {
    let string = '';
    if (address1 && address1.length > 0) {
      string += address1;
    }
    if (address2 && address2.length > 0) {
      string += ', ' + address2;
    }
    if (city && city.length > 0) {
      string += ', ' + city;
    }
    if (state && state.length > 0) {
      string += ', ' + state;
    }
    if (zip && zip.length > 0) {
      string += ', ' + zip;
    }
    return string;
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <Col xs="12" sm="8">
          <PageTitle sm="12" title="Add Property" subtitle="Properties" className="text-sm-left" />
        </Col>
        <Col xs="12" sm="4" className={"mt-4 mt-sm-0"}>
          <ReactFlagsSelect
            selected={iso2.toUpperCase()}
            onSelect={code => {
              setIso2(code.toLowerCase())
              setProperty(null)
            }}
            className='react-flags-select-bootstrap'
            selectedSize={14}
            optionSize={14}
            countries={['AU', 'CA', 'HK', 'IE', 'MY', 'NZ', 'PT', 'QA', 'SA', 'SG', 'ZA', 'ES', 'AE', 'GB', 'US']}
          />
        </Col>
      </Row>

      {/* First Row of Posts */}
      <Row>
        <Col md="4" className="mb-4">
          <Card small className="card-post card-post--1" style={{minHeight: '331px'}}>
            <CardHeader className="border-bottom">
              Adding a property
            </CardHeader>
            <CardBody>
              <p className="ml-auto text-left text-semibold text-reagent-gray mb-0">
                When adding a property use our google address lookup or you can manually enter an address.<br />
                <br />
                Once you have selected a Google address you can edit the details to closer match, so for example, you can add a unit number or other information.
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col md="8" className="mb-4">
          <Card className={'mb-0'}>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Address</h6>
              <p className='header-subtext'>Find a property using our Google integration or manually enter the property details below</p>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        <Col md="12" className="form-group">
                          <label htmlFor="feFindProperty"></label>
                          <GooglePlacesAutocomplete
                            ref={addressSelector}
                            apiKey="AIzaSyAs4SS_IliPH7OVWUd5rz57mMGQOm3aswQ"
                            selectProps={{
                              value: property ? property.label : null,
                              onChange: setAddressFromSearch,
                              placeholder: "Start typing an address..",
                              isClearable: true,
                            }}
                            apiOptions={{ language: iso2.toUpperCase(), region: iso2.toUpperCase() }}
                            autocompletionRequest={{componentRestrictions: {country: iso2.toUpperCase()}}}
                          />
                        </Col>
                      </Row>
                      {!!property && (
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feNewAddress">Selected Address (edit if required)</label>
                            <FormInput
                                id="feNewAddress"
                                placeholder=""
                                value={property.address}
                                onChange={(event) => setProperty({...property, address: event.target.value})}
                                invalid={property.address.length < 10}
                              />
                              <FormFeedback>Please enter an address</FormFeedback>
                          </Col>
                        </Row>
                      )}                      
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
          {!property && (
            <Card style={{marginTop: -1, marginBottom: 0}}>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col lg='6'>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feAddress1">Address 1</label>
                            <FormInput
                              id="feAddress1"
                              placeholder="Address 1"
                              value={address1}
                              onChange={(event) => setAddress1(event.target.value)}
                            />
                          </Col>
                        </Row>                        
                      </Form>
                    </Col>
                    <Col lg='6'>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feAddress2">Address 2</label>
                            <FormInput
                              id="feAddress2"
                              placeholder="Address 2"
                              value={address2}
                              onChange={(event) => setAddress2(event.target.value)}
                            />
                          </Col>
                        </Row>                        
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='6'>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feCity">{translation('town', iso2, true)}</label>
                            <FormInput
                              id="feCity"
                              placeholder={translation('town', iso2, true)}
                              value={city}
                              onChange={(event) => setCity(event.target.value)}
                            />
                          </Col>
                        </Row>                        
                      </Form>
                    </Col>
                    <Col lg='6'>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feState">{translation('county', iso2, true)}</label>
                            <FormInput
                              id="feState"
                              placeholder={translation('county', iso2, true)}
                              value={state}
                              onChange={(event) => setState(event.target.value)}
                            />
                          </Col>
                        </Row>                        
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='6'>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feCity">{translation('postcode', iso2, true)}</label>
                            <FormInput
                              id="feZip"
                              placeholder={translation('postcode', iso2, true)}
                              value={zip}
                              onChange={(event) => setZip(event.target.value)}
                            />
                          </Col>
                        </Row>                        
                      </Form>
                    </Col>
                    <Col></Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          )}
          <Card style={{marginTop: -1, marginBottom: 0}}>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        <Col md="12" className="form-group">
                        <Button
                          className="mr-1"
                          theme="secondary"
                          onClick={() => window.location.href='/properties'}
                        >Cancel</Button>
                        <Button
                          theme="accent"
                          onClick={() => {
                            if (!!property) {
                              props.addProperty(property)
                            } else {
                              props.addProperty({
                                address: generateAddressString()
                              })
                            }
                          }}
                        >Next</Button>
                        </Col>
                      </Row>                        
                    </Form>
                  </Col>
                  <Col></Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AddProperty;
