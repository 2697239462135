import Showings from './Showings';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getProperty, getViewing, getContact} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user, displayUser} = getUser(state);
  const {properties} = getProperty(state);
  const {viewings, requestedViewings, tours} = getViewing(state);
  const {contacts} = getContact(state);


  return {
    user,
    properties,
    viewings,
    requestedViewings,
    contacts,
    tours,
    displayUser,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Showings);
