import { combineReducers } from 'redux';
import user, * as fromUser from './userReducer';
import login, * as fromLogin from './loginReducer';
import property, * as fromProperty from './propertyReducer';
import support, * as fromSupport from './supportReducer';
import form, * as fromForm from './formReducer';
import contact, * as fromContact from './contactReducer';
import viewing, * as fromViewing from './viewingReducer';

export default combineReducers({
  user,
  login,
  property,
  support,
  form,
  contact,
  viewing,
});

export const getUser = (state) => fromUser.getUser(state.user);
export const getLogin = (state) => fromLogin.getLogin(state.login);
export const getProperty = (state) => fromProperty.getProperty(state.property);
export const getSupport = (state) => fromSupport.getSupport(state.support);
export const getForm = (state) => fromForm.getForm(state.form);
export const getContact = (state) => fromContact.getContact(state.contact);
export const getViewing = (state) => fromViewing.getViewing(state.viewing);
