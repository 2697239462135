// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  user: {},
  loadingUser: false,
  loadedUser: false,
  savedUserAvatar: false,
  savedUserPrimaryColor: false,
  updatedUser: false,
  savedCompanyLogo: false,
  displayUser: {},
  loadingDisplayUser: false,
  loadedDisplayUser: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_USER:
      return {
        ...state, loadingUser: true, loadedUser: false, user: {}
      };
    case actionConstants.LOADED_USER:
      return {
        ...state, loadingUser: false, loadedUser: true, user: action.payload, savedUserAvatar: false, updatedUser: false, savedUserPrimaryColor: false, savedCompanyLogo: false,
      };
    case actionConstants.SAVED_USER_AVATAR:
      return {
        ...state, savedUserAvatar: true
      };
      case actionConstants.SAVED_COMPANY_LOGO:
        return {
          ...state, savedCompanyLogo: true
        };  
    case actionConstants.SAVED_USER_PRIMARY_COLOR:
      return {
        ...state, savedUserPrimaryColor: true
      };
    case actionConstants.UPDATED_USER:
      return {
        ...state, updatedUser: true
      };
    case actionConstants.LOADING_DISPLAY_USER:
      return {
        ...state, loadingDisplayUser: true, loadedDisplayUser: false, displayUser: {}
      };
    case actionConstants.LOADED_DISPLAY_USER:
      return {
        ...state, loadingDisplayUser: false, loadedDisplayUser: true, displayUser: action.payload,
      };  
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getUser = ({
  user, loadingUser, loadedUser, savedUserAvatar, updatedUser, savedUserPrimaryColor, savedCompanyLogo, displayUser, loadingDisplayUser, loadedDisplayUser,
}) => ({
  user,
  loadingUser,
  loadedUser,
  savedUserAvatar,
  updatedUser,
  savedUserPrimaryColor,
  savedCompanyLogo,
  displayUser,
  loadingDisplayUser,
  loadedDisplayUser,
});
