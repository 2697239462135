import firebase from 'firebase';
import * as actionConstants from '../actionConstants';

export const searchUsers = (searchTerm) => dispatch => {
  let term = searchTerm.toLowerCase();
  dispatch({ type: actionConstants.SEARCHING_USERS });
  firebase
    .firestore()
    .collection('users')
    .get()
    .then(querySnapshot => {
      let searchUserResults = [];
      querySnapshot.forEach(doc => {
        let found = false;
        if (doc.data().name && doc.data().name.toLowerCase().indexOf(term) !== -1) {
          found = true;
        }
        if (doc.data().email && doc.data().email.toLowerCase().indexOf(term) !== -1) {
          found = true;
        }
        if (doc.data().phoneNumber && doc.data().phoneNumber.toLowerCase().indexOf(term) !== -1) {
          found = true;
        }
        if (found) {
          searchUserResults.push({id: doc.id, ...doc.data()})
        }
      });
      dispatch({ type: actionConstants.SEARCH_USER_RESULTS, payload: searchUserResults})
    })
};

export const getSelectedUser = userId => dispatch => {

  return firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .get()
    .then(async doc => {
      let properties = await doc.ref.collection('properties').get().then(querySnapshot => {
        let properties = [];
        querySnapshot.forEach(propDoc => {
          properties.push({id: propDoc.id, user: userId, ...propDoc.data()})
        });
        return properties;
      }).catch(error => []);

      let viewings = await doc.ref.collection('viewings').get().then(querySnapshot => {
        let viewings = [];
        querySnapshot.forEach(viewingDoc => {
          viewings.push({id: viewingDoc.id, user: userId, ...viewingDoc.data()})
        });
        return viewings;
      }).catch(error => []);

      dispatch({ type: actionConstants.LOADED_SELECTED_USER, payload: {
        user: {id: doc.id, ...doc.data()},
        properties: properties,
        viewings: viewings,
      }})
    })
    .catch(error => console.log(error))

};

export const searchProperties = (searchTerm) => dispatch => {
  let term = searchTerm.toLowerCase();
  firebase
    .firestore()
    .collectionGroup('properties')
    .get()
    .then(querySnapshot => {
      let searchPropertyResults = [];
      querySnapshot.forEach(doc => {
        let found = false;
        if (doc.data().address && doc.data().address.toLowerCase().indexOf(term) !== -1) {
          found = true;
        }
        if (found) {
          searchPropertyResults.push({id: doc.id, user: doc.ref.parent.parent.id, ...doc.data()})
        }
      });
      dispatch({ type: actionConstants.SEARCH_PROPERTY_RESULTS, payload: searchPropertyResults})
    })
};
