import EditContact from './EditContact';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getContact} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user} = getUser(state);
  const {addingContact, addedContact, contacts, contact, loadedContacts, loadingContacts} = getContact(state);

  return {
    user,
    addingContact,
    addedContact,
    contacts,
    contact,
    loadedContacts,
    loadingContacts,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditContact);
