import React from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ActionCreators} from '../../../actions';
import { getContact } from "../../../reducers/rootReducer";

const NavbarContactSearch  = ({contactsSearchTerm, setContactsSearchTerm}) => (
  <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
    <InputGroup seamless className="ml-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>
          <i className="material-icons">search</i>
        </InputGroupText>
      </InputGroupAddon>
      <FormInput
        className="navbar-search"
        placeholder="Search contacts..."
        onChange={event => setContactsSearchTerm(event.target.value)}
        value={contactsSearchTerm}
      />
    </InputGroup>
  </Form>
);

const mapStateToProps = state => {
  const {contactsSearchTerm} = getContact(
    state,
  );

  return {
    contactsSearchTerm,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarContactSearch);
