import React from "react";
import { Navigate } from "react-router-dom";
import firebase from 'firebase';

// Layout Types
import { DefaultLayout, LoginLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfile from "./views/UserProfile/index";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import Support from "./views/Support/index";
import User from "./views/User/index";
import Property from "./views/Property/index";
import Properties from "./views/Properties/index";
import Login from "./views/Login/index";
import ShowingDefaults from "./views/ShowingDefaults/index";
import CalendarLinkSettings from "./views/CalendarLinkSettings/index";
import AddProperty from "./views/AddProperty/index";
import EditProperty from "./views/EditProperty/index";
import Contacts from "./views/Contacts/index";
import EditContact from "./views/EditContact/index";
import Showings from "./views/Showings/index";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Navigate to="/properties" />
  },
  {
    path: "/login",
    exact: true,
    layout: LoginLayout,
    component: Login
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/user-profile",
    layout: DefaultLayout,
    component: UserProfile
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/support",
    layout: DefaultLayout,
    component: Support
  },
  {
    path: "/properties",
    layout: DefaultLayout,
    component: Properties
  },
  {
    path: "/contacts",
    layout: DefaultLayout,
    component: Contacts
  },
  {
    path: "/user-support",
    layout: DefaultLayout,
    component: User
  },
  {
    path: "/property-support",
    layout: DefaultLayout,
    component: Property
  },
  {
    path: "/add-property",
    layout: DefaultLayout,
    component: AddProperty
  },
  {
    path: "/edit-property/:propertyId",
    layout: DefaultLayout,
    component: EditProperty
  },
  {
    path: "/add-contact",
    layout: DefaultLayout,
    component: EditContact
  },
  {
    path: "/edit-contact/:contactId",
    layout: DefaultLayout,
    component: EditContact
  },
  {
    path: "/showing-defaults",
    layout: DefaultLayout,
    component: ShowingDefaults
  },
  {
    path: "/calendar-link-settings",
    layout: DefaultLayout,
    component: CalendarLinkSettings
  },
  {
    path: "/showings",
    layout: DefaultLayout,
    component: Showings
  },
];
