import AddProperty from './AddProperty';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getProperty} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user} = getUser(state);
  const {properties, propertySearchTerm, addingProperty, addedProperty, property} = getProperty(state);

  return {
    user,
    properties,
    propertySearchTerm,
    addingProperty,
    addedProperty,
    property,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddProperty);
