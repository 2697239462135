import React, {useEffect, useState} from "react";
import {
  Card,
  CardHeader,
  FormCheckbox,
  FormFeedback,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Container,
  Form,
  FormInput,
  Button,
  Alert
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import UserDetails from "../../components/user-profile/UserDetails";
import CompanyLogo from "../../components/user-profile/CompanyLogo";
import UserAccountDetails from "../../components/user-profile/UserAccountDetails";
import BusinessSettings from "../../components/user-profile/BusinessSettings";

const CalendarLinkSettings = (props) => {
  const filterForms = forms => {
    return forms.filter(form => form.type === 'Qualification');
  }

  const [showCompanyDetails, setShowCompanyDetails] = useState(!!props.user.showCompanyDetails);
  const [detailsToShow, setDetailsToShow] = useState(props.user.detailsToShow)
  const [showRegistrationButton, setShowRegistrationButton] = useState(!!props.user.showRegistrationButton);
  const [registrationButtonText, setRegistrationButtonText] = useState(props.user.registrationButtonText || 'REGISTER / INQUIRE');
  const [showBookingButton, setShowBookingButton] = useState(!!props.user.showBookingButton);
  const [bookingButtonText, setBookingButtonText] = useState(props.user.bookingButtonText || 'BOOK SHOWING');
  //const [primaryConsumerColor, setPrimaryConsumerColor] = useState(props.user.primaryConsumerColor || '000000');
  const [updatedUser, setUpdatedUser] = useState(false);

  useEffect(() => {
    document.title = 'Calendar Link Settings: Real Happy Dashboard';
  }, [])

  useEffect(() => {
    if (props.updatedUser) {
      setUpdatedUser(true);
      setTimeout(() => setUpdatedUser(false), 10000);
    }
  }, [props.updatedUser]);

  return (
    <Container fluid className="main-content-container px-0">
      <Container fluid className="px-0">
        {updatedUser && (
          <Alert className="mb-0" theme={'success'}>
            <i className="fa fa-info mx-2"></i> Your calendar link settings have been updated!
          </Alert>
        )}
      </Container>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Calendar Link Settings" subtitle="Settings" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Display settings</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feDetailsToShow">Show Agent/Company details</label>
                            <FormSelect id="feDetailsToShow" value={detailsToShow ? detailsToShow : showCompanyDetails ? 'company' : 'agent'} onChange={(event) => setDetailsToShow(event.target.value)}>
                              <option value='both'>Agent and Company</option>
                              <option value='agent'>Agent only</option>
                              <option value='company'>Company only</option>
                            </FormSelect>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                          <FormCheckbox
                                toggle
                                checked={showRegistrationButton}
                                onChange={() => setShowRegistrationButton(!showRegistrationButton)}
                              >
                                Enable registration button
                            </FormCheckbox>
                          </Col>
                        </Row>
                        {showRegistrationButton && (
                          <Row form>
                            <Col md="12" className="form-group">
                              <label htmlFor="feRegistrationButtonText">Registration button text</label>
                              <FormInput
                                id="feRegistrationButtonText"
                                placeholder="Registration button text"
                                value={registrationButtonText}
                                onChange={(event) => setRegistrationButtonText(event.target.value)}
                                invalid={showRegistrationButton && registrationButtonText.length < 3}
                              />
                              <FormFeedback>Please enter text for the registration button.</FormFeedback>
                            </Col>
                          </Row>
                        )}
                        <Row form>
                          <Col md="12" className="form-group">
                          <FormCheckbox
                                toggle
                                checked={showBookingButton}
                                onChange={() => setShowBookingButton(!showBookingButton)}
                              >
                                Enable showings button
                            </FormCheckbox>
                          </Col>
                        </Row>
                        {showBookingButton && (
                          <Row form>
                            <Col md="12" className="form-group">
                              <label htmlFor="feBookingButtonText">Showings button text</label>
                              <FormInput
                                id="feBookingButtonText"
                                placeholder="Showing button text"
                                value={bookingButtonText}
                                onChange={(event) => setBookingButtonText(event.target.value)}
                                invalid={showBookingButton && bookingButtonText.length < 3}
                              />
                              <FormFeedback>Please enter text for the showings button button.</FormFeedback>
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    <Button
                      disabled={(showBookingButton && bookingButtonText.length < 3) || (showRegistrationButton && registrationButtonText.length < 3)}
                      theme="accent"
                      onClick={() => props.updateUser({detailsToShow, showBookingButton, bookingButtonText, showRegistrationButton, registrationButtonText})}
                    >Update Calendar Link Settings</Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  )
};

export default CalendarLinkSettings;
